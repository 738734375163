<template>
  <v-app id="login">
    <v-container fluid class="mx-auto my-auto">
      <v-row>
        <v-col md="8" class="mx-auto">
          <v-card class="mx-auto my-auto" shaped>
            <v-row>
              <v-col lg="6" md="12" sm="12">
                <v-card-text class="mx-auto my-auto">
                  <h4 class="font-weight-normal text-center">
                    Virtual Cybersecurity Operation Center
                  </h4>
                </v-card-text>

                <v-img
                  class="mx-auto my-auto"
                  :src="logo_vinsight"
                  :width="logo_width"
                  :height="logo_height"
                ></v-img>
              </v-col>
              <v-col lg="6" md="12" sm="12" class="mx-auto my-auto">
                <v-row>
                  <v-col>
                    <div v-if="!forgotPassword && !forgotConfirm">
                      <v-card-text>
                        <h1 class="font-weight-normal text-center">
                          Sign In
                        </h1>
                      </v-card-text>
                      <v-form @keyup.native.enter="login">
                        <v-col>
                          <div v-if="logonFailure">
                            <h4 class="text-center" style="color:red">
                              {{message}}
                            </h4>
                          </div>
                          <v-text-field
                            prepend-icon="mdi-account-lock"
                            v-model="username"
                            label="Username (Email)"
                            filled
                            rounded
                            outlined
                            dense
                            autofocus
                            required
                          ></v-text-field>
                          <v-text-field
                            prepend-icon="mdi-lock"
                            v-model="password"
                            :error-messages="errors"
                            label="Password"
                            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPass = !showPass"
                            required
                            outlined
                            rounded
                            dense
                            dark
                            filled
                            :type="showPass ? 'text' : 'password'"
                          ></v-text-field>
                          <v-row>
                            <v-col md="6">
                              <v-select
                                prepend-inner-icon="mdi-clock"
                                v-model="timetoken"
                                :items="timesession"
                                label="Session Login"
                                dense
                                outlined
                              ></v-select>
                            </v-col>
                            <v-col md="6" class="text-right">
                              <div
                                dark
                                @click="forgotPassword = true"
                                class="font-weight-normal"
                              >
                                <h5>Forgot Password?</h5>
                              </div>
                            </v-col>
                          </v-row>

                          <div class="text-center">
                            <v-btn
                              rounded
                              color="#303030"
                              style="border: 1px solid #626262;"
                              dark
                              @click="login"
                              class="font-weight-normal "
                            >
                              Sign in
                            </v-btn>
                          </div>
                        </v-col>
                      </v-form>
                    </div>

                    <div v-else-if="forgotPassword">
                      <v-card-text>
                        <h4>Reset Password</h4>
                      </v-card-text>
                      <v-form @keyup.native.enter="login">
                        <v-col
                          lg6
                          md6
                          sm6
                          xs6
                          offset-lg3
                          mt-3
                          offset-md3
                          offset-sm3
                          offset-xs3
                        >
                          <v-text-field
                            v-model="username"
                            label="Enter your email"
                            filled
                            rounded
                            outlined
                            dense
                            autofocus
                            required
                          />
                        </v-col>
                        <v-col>
                          <v-btn
                            rounded
                            color="red"
                            dark
                            @click="resetPassword"
                            class="font-weight-normal"
                          >
                            Reset Password
                          </v-btn>
                          <v-btn
                            rounded
                            color="black"
                            dark
                            @click="forgotPassword = false"
                            class="font-weight-normal"
                          >
                            Cancel
                          </v-btn>
                        </v-col>
                      </v-form>
                    </div>

                    <div v-else-if="forgotConfirm">
                      <v-card-text>
                        <img
                          :src="logo"
                          alt="Ragnar Logo"
                          :width="logo_width"
                          :height="logo_height"
                          class="responsive"
                        />
                        <h4 class="font-weight-normal mt-3">
                          The email has been sent to <b>{{ username }}</b>
                        </h4>
                        <h4>Please check your email</h4>
                      </v-card-text>
                      <v-col pb-3>
                        <v-btn
                          round
                          :color="buttonColor"
                          dark
                          @click="forgotConfirm = false"
                        >
                          Back to Login Page
                        </v-btn>
                      </v-col>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
import jwtDecode from 'jwt-decode'

export default {
  name: "Login",
  beforeCreate() {
    sessionStorage.removeItem("user-token");
  },
  data() {
    return {
      username: "",
      password: "",
      logonFailure: false,
      image: null,
      logo: null,
      buttonColor: null,
      logo_width: null,
      logo_height: null,
      forgotPassword: false,
      forgotConfirm: false,
      showPass: false,
      timetoken: "60m",
      timesession: [
        {
          text: "1 Hour",
          value: "60m",
        },
        {
          text: "24 Hour",
          value: "24hr",
        },
      ],
      check_customer_in_db : false,
      ampid:''
    }; 
  },
  created() {
    this.logo_vinsight = require("../assets/img/V-SIGHT_logo-03.png");
    this.logo_width = "140px";
    this.logo_height = "auto";
    this.test() //******************************* */
  },
  methods: {

    async test () {
      let apiUrl = "https://api.dehashed.com"+"/search?query=domain:@winnergroup.co.th&size=10000";
        let header = {"Accept": "application/json"}
        let dehashed_user = 'admin@ragnar.co.th'
        let dehashed_pass = 'c5doqtzi8jedklu9cfuvat7wp1p2wctn'
        // console.log(res);
        axios.get(apiUrl,{headers:header,auth:{dehashed_user,dehashed_pass}}).then(response => {
            console.log(response.data)
          }).catch(e => {
            alert(e.response.data.errmsg)
            // this.isLoading = false
          })
    },
    async login() {
      this.logonFailure = false;
      this.message = ''
      // var response = {}
      try {
        let response = await axios.post(
          // "https://staging.ilog.ai:8000" + "/api/auth/login",
          "https://portal.ilog.ai:8001" + "/api/auth/login",
          {
            username: this.username,
            password: this.password,
            timetoken: this.timetoken,
            // username: "vinsight-sorkon",
            // password: "R@GN@R2521",
            // timetoken: "24hr",
          }
        );
        if (response.data.success) {
          this.decoded = jwtDecode(response.data.token);
          this.customer_name = this.decoded.customer_name;
          this.ampid = this.decoded.ampid
          this.token = response.data.token
          console.log(this.token)
          axios.defaults.headers.common['Authorization'] = this.token
          try {
          let response_v = await axios.post(
            "https://backstage.v-insight.co:8001" + "/api/Check_Customer_in_DB/",
            {
              customer_name: this.customer_name,
            }
          );
          this.check_customer_in_db = response_v.data;
          // this.check_customer_in_db = false
          if (this.check_customer_in_db) {
            // this.message = "Welcome in V-INSIGHT";
            sessionStorage.setItem("user-token", response.data.token);
            this.$router.push({
              name: "dashboard",
              params: { isExpired: response.data.isExpired },
            });
            window.analytics.identify(this.username, this.decode, {
                        integrations: {
                            Amplitude: {
                            'session_id': this.ampid
                            }
                        }
            })

          } else {
            this.logonFailure = true;
            this.message = "Unauthorized Access in V-INSIGHT";
            //this.$router.push({ name: "login" });
          }
          }catch (error) {
            this.logonFailure = true;
            this.message = "Authentication failed Unable to connect to server"       
          }
        } else {
          this.logonFailure = true;
          this.message = "Authentication failed"
        }
      } catch (error) {
        // console.log(error);
        this.message = "Authentication failed Unable to connect to server"
        this.logonFailure = true;
      }
    },
    async resetPassword() {
      try {
        let response = await axios.post(
          "https://dev.ilog.ai:8003" + "/api/auth/forgot_password",
          {
            username: this.username,
          }
        );
        if (response.data.success) {
          this.forgotPassword = false;
          this.forgotConfirm = true;
        }
      } catch (error) {
        alert("Incorrect email");
        console.error(error);
      }
    },
  },
};
</script>

<style scoped></style>
