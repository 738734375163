<template>
  <app id="dashboard">
    <v-row>
      <v-col sm="2" md="auto" lg="auto">
        <h1 class="page-title">Dashboard</h1>
      </v-col>
    </v-row>
    <!-- ******************************** -->
    <v-row >
          <v-col sm="auto" md="auto" lg="auto">
            <VueCtkDateTimePicker
              v-model="start"
              format="YYYY-MM-DD HH:mm"
              label="Select Time"
              :minute-interval="1"
              class="field-input[data-v-5b500588]"
              dark
            >
            </VueCtkDateTimePicker>
          </v-col>

          <v-col sm="auto" md="auto" lg="auto">
          <h2>To</h2>
          </v-col>

          <v-col sm="auto" md="auto" lg="auto">
            <VueCtkDateTimePicker
              v-model="end"
              format="YYYY-MM-DD HH:mm"
              label="Select Time"
              :minute-interval="1"
              class="field-input[data-v-5b500588] d-flex justify-start"
              dark
            >
            </VueCtkDateTimePicker>
          </v-col>
            <v-col sm="auto" md="auto" lg="auto">
            <v-btn
              rounded
              color="#32CD32"
              dark
              @click="dateFillter"
            >
              SUBMIT
            </v-btn>
          </v-col>
          <v-col sm="auto" md="auto" lg="auto">
            <v-btn
              rounded
              color="#121212"
              class="mx-5"
              style="border: 1px solid #C7C7C7;"
              dark
              @click="clearFillter"
            >
              <v-icon>mdi-notification-clear-all</v-icon>
              <div style="font-weight: lighter;">CLEAR</div>
            </v-btn>
            </v-col>

          <v-spacer></v-spacer>

          <v-col sm="auto" md="auto" lg="auto">
            <v-btn rounded color="#3D3D3D" dark>
              <v-icon>mdi-pdf-box</v-icon>
              <div style="text-transform: capitalize; font-weight: lighter;">
                Export Report PDF
              </div>
            </v-btn>          
          </v-col>
    </v-row>
    <!-- *******************!-->
    <v-row>
      <v-col lg="3" sm="3" cols="12">
        <v-card :loading="card_loading">
          <v-card-title class="pa-6 pb-3">
            <p>Threat</p>
          </v-card-title>
          <v-card-text class="pa-6 pt-0">
            <v-row no-gutters>
              <v-col cols="auto">
                <span style="font-size: 42px">{{ countalldevicethreat }} </span>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <div v-if="countalldevicethreat < old_countalldevicethreat">
                  {{ compare_threat }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="success" v-bind="attrs" v-on="on">
                        mdi-arrow-bottom-right</v-icon
                      >
                    </template>
                    <span>
                      Threat has decreased {{ compare_threat }} from the
                      {{ time_compare }}
                    </span>
                  </v-tooltip>
                </div>
                <div
                  v-else-if="countalldevicethreat > old_countalldevicethreat"
                >
                  {{ compare_threat }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="error" v-bind="attrs" v-on="on">
                        mdi-arrow-top-right</v-icon
                      >
                    </template>
                    <span>
                      Threat has increased {{ compare_threat }} from the
                      {{ time_compare }}
                    </span>
                  </v-tooltip>
                </div>
                <div
                  v-else-if="countalldevicethreat == old_countalldevicethreat"
                >
                  {{ compare_threat }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="warning" v-bind="attrs" v-on="on">
                        mdi-approximately-equal</v-icon
                      >
                    </template>
                    <span>
                      Threat has equal {{ compare_threat }} from the
                      {{ time_compare }}
                    </span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col lg="3" sm="3" cols="12">
        <v-card :loading="card_loading">
          <v-card-title class="pa-6 pb-3">
            <p>Anomalies</p>
          </v-card-title>
          <v-card-text class="pa-6 pt-0">
            <v-row no-gutters>
              <v-col cols="auto">
                <span style="font-size: 42px"
                  >{{ countalldeviceanomaly }}
                </span>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <div v-if="countalldeviceanomaly < old_countalldeviceanomaly">
                  {{ compare_anomaly }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="success" v-bind="attrs" v-on="on">
                        mdi-arrow-bottom-right</v-icon
                      >
                    </template>
                    <span
                      >Anomaly has decreased {{ compare_anomaly }} from the
                      {{ time_compare }}
                    </span>
                  </v-tooltip>
                </div>
                <div
                  v-else-if="countalldeviceanomaly > old_countalldeviceanomaly"
                >
                  {{ compare_anomaly }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="error" v-bind="attrs" v-on="on">
                        mdi-arrow-top-right</v-icon
                      >
                    </template>
                    <span
                      >Anomaly has increased {{ compare_anomaly }} from the
                      {{ time_compare }}
                    </span>
                  </v-tooltip>
                </div>
                <div
                  v-else-if="countalldeviceanomaly == old_countalldeviceanomaly"
                >
                  {{ compare_anomaly }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="warning" v-bind="attrs" v-on="on">
                        mdi-approximately-equal</v-icon
                      >
                    </template>
                    <span>
                      Anomaly has equal {{ compare_anomaly }} from the
                      {{ time_compare }}
                    </span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col lg="3" sm="3" cols="12">
        <v-card :loading="card_loading">
          <v-card-title class="pa-6 pb-3">
            <p>Users</p>
          </v-card-title>
          <v-card-text class="pa-6 pt-0">
            <v-row no-gutters>
              <v-col cols="auto" class="my-auto">
                <span style="font-size: 42px">{{ countallusers }} </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col lg="3" sm="3" cols="12">
        <v-card :loading="card_loading">
          <v-card-title class="pa-6 pb-3">
            <p>Devices</p>
          </v-card-title>
          <v-card-text class="pa-6 pt-0">
            <v-row no-gutters>
              <v-col cols="auto" class="my-auto">
                <span style="font-size: 42px">{{ countdevice }} </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- *******************!-->
    <!-- *******************!-->
    <v-row>
      <v-col cols="12">
        <v-card height="540">
          <v-card-text>
            <v-row align="center" class="justify-end">
              <v-col cols="2">
                <v-select
                  v-model="e2"
                  :items="intervaltype"
                  prepend-icon="mdi-clock-time-eight-outline"
                  menu-props="auto"
                  hide-details
                  label="Select"
                  single-line
                ></v-select>
              </v-col>
            </v-row>

            <div v-if="loading">
              <MoonLoader
                :color="'#BC4D2A'"
                class="d-flex justify-center align-center"
              ></MoonLoader>
            </div>
            <div v-else>
              <v-chart
                :options="option"
                :autoresize="true"
                :responsive="true"
                :style="{ height: height, width: width }"
                dark
                class="pa-1 pb-2"
              ></v-chart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- *******************!-->
    <!-- *******************!-->
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title>
            Threat
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="arrlastthreat"
            :loading="loadTable_threat"
          >
            <template v-slot:item.ilog="{ item }">
              <v-img
                class="mx-auto my-auto pointer"
                :src="logo_ilog"
                @click="linkilog(item)"
                width="18px"
                height="auto"
              >
              </v-img>
            </template>

            <v-progress-linear
              slot:progress
              color="blue"
              indeterminate
            ></v-progress-linear>
          </v-data-table>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-card-title>
            Anomaly
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="arrlastanomaly"
            :loading="loadTable_anomaly"
          >
            <template v-slot:item.ilog="{ item }">
              <v-img
                class="mx-auto my-auto pointer"
                :src="logo_ilog"
                @click="linkilog(item)"
                width="18px"
                height="auto"
              >
              </v-img>
            </template>

            <v-progress-linear
              slot:progress
              color="blue"
              indeterminate
            ></v-progress-linear>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </app>
</template>

<script>
//import {GridLayout, GridItem} from 'vue-grid-layout'
import jwtDecode from "jwt-decode";

import axios from "axios";

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/toolbox";
// import { toolbox as feature } from 'echarts/lib/langEN'

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

// import Test from '../components/test';
// import XBar from '../components/x-bar';
//import Anomaly from '../components/test_anomaly';

//import Anomaly1 from '../components/test_anomaly1';
//import Anomaly1 from '../components/test_anomaly1';

export default {
  name: "dashboard",
  components: {
    //  GridLayout,
    //  GridItem,
    VueCtkDateTimePicker,
    MoonLoader,
    // Test,
    // XBar,
    "v-chart": ECharts,
    //Anomaly1
  },
  data() {
    return {
      width: "100%",
      height: "475px",
      loading: false,
      // loadTable: false,
      loadTable_anomaly: false,
      loadTable_threat: false,
      start: "",
      end: "",
      startdate: Date.parse(new Date()) - 86400000,
      enddate: Date.parse(new Date()),

      // compare_startdate: Date.parse(new Date()) - 2592000000 * 2,
      // compare_enddate: Date.parse(new Date()) - 2592000000,

      anomalyandthreat: [],
      arrlastanomaly: [],
      arrlastthreat: [],
      headers: [
        { text: "Date", value: "time_detect" },
        { text: "Case", value: "usecase" },
        {
          text: "Device name",
          value: "device_vendor",
        },
        // { text: "ilog", value: "ilog", align: "center" },
        // { text: "Action", value: "action" },
      ],
      arrgraphdate: [],
      arrgraphthreat: [],
      arrgraphanomaly: [],
      countalldeviceanomaly: 0,
      countalldevicethreat: 0,
      old_countalldeviceanomaly: 0,
      old_countalldevicethreat: 0,
      countallusers: "",
      countdevice: "",
      option: {},
      card_loading: true,
      compare_anomaly: 0,
      compare_threat: 0,
      time_compare: "",
      intervaltype: [
        {
          text: "Auto",
          value: "auto",
        },
        {
          text: "minutes (m)",
          value: "1m",
        },
        {
          text: "hours (h)",
          value: "1h",
        },
        {
          text: "days (d)",
          value: "1d",
        },
        {
          text: "weeks (w)",
          value: "1w",
        },
        {
          text: "months (M)",
          value: "1M",
        },
        {
          text: "years (y)",
          value: "1y",
        },
      ],

      // compareanomaly :'',
      // comparethreat:''

      //       windowSize: {
      //   x: 0,
      //   y: 0,
      // },
      // x : ''
    };
  },

  created() {
    this.logo_ilog = require("../assets/img/favicon.png");
    this.token = sessionStorage.getItem("user-token");
    this.decoded = jwtDecode(this.token);

    this.customer_name = this.decoded.customer_name;
    this.ampid = this.decoded.ampid;
    //await this.getDashboardTemplate()
    //await this.getField()
    // console.log(this.startdate);
    // console.log(this.customer_name);

    // console.log(this.compare_startdate);
    // console.log(this.compare_enddate);

    this.countallanomalyandthreat();
    this.lastanomaly();
    this.lastthreat();
    this.databargraph();
    this.compare_countallanomalyandthreat();

    // window.analytics.track('[Dashbord]', {}, {
    //     integrations: {
    //       Amplitude: {
    //         'session_id': this.ampid
    //       }
    //     }
    // })
  },
  // mounted () {
  //   this.onResize()
  // },
  methods: {
    // onResize () {
    //       this.x = window.innerWidth
    //       this.width = (this.x-400) + 'px'
    // },

    async countallanomalyandthreat() {
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/Dashboard/CountallAnomalyandThreat_Timerange/",
          {
            customer_name: this.customer_name,
            start_date: this.startdate,
            end_date: this.enddate,
          }
        );
        this.countalldeviceanomaly = response.data.countalldeviceanomaly;
        this.countalldevicethreat = response.data.countalldevicethreat;
        this.countallusers = response.data.countallusers;
        this.countdevice = response.data.countdevice;
        // console.log(this.countalldeviceanomaly);
        this.compare_countallanomalyandthreat();
        this.card_loading = false;
      } catch (error) {
        console.error(error);
      }
    },

    async compare_countallanomalyandthreat() {
      // console.log("XXXXXXXXXXXXXXXXXXXXXX");
      // console.log(this.startdate);
      // console.log(this.enddate);
      var timerange = this.enddate - this.startdate;
      this.time_compare = this.dhm(timerange);

      console.log(timerange);
      console.log("+++++++++++");
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/Dashboard/CountallAnomalyandThreat_Timerange/",
          {
            customer_name: this.customer_name,
            start_date: this.startdate - timerange,
            end_date: this.enddate - timerange,
          }
        );
        this.old_countalldeviceanomaly = response.data.countalldeviceanomaly;
        this.old_countalldevicethreat = response.data.countalldevicethreat;

        this.compare_anomaly = Math.abs(
          this.countalldeviceanomaly - this.old_countalldeviceanomaly
        );
        this.compare_threat = Math.abs(
          this.countalldevicethreat - this.old_countalldevicethreat
        );

        this.card_loading = false;
      } catch (error) {
        console.error(error);
      }
    },

    dhm(t) {
      var cd = 24 * 60 * 60 * 1000,
        ch = 60 * 60 * 1000,
        d = Math.floor(t / cd),
        h = Math.floor((t - d * cd) / ch),
        m = Math.round((t - d * cd - h * ch) / 60000),
        pad = function(n) {
          return n < 10 ? "0" + n : n;
        };
      if (m === 60) {
        h++;
        m = 0;
      }
      if (h === 24) {
        d++;
        h = 0;
      }
      return d + " Days " + pad(h) + " Hours " + pad(m) + " Minutes";
    },

    async lastanomaly() {
      this.arrlastanomaly = [];
      this.loadTable_anomaly = true;
      // console.log("XXXXXXXXXXXXXXXXXXXXXX");
      // console.log(this.startdate);
      // console.log(this.enddate);
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/Dashboard/Lastdata_Anomaly/",
          {
            customer_name: this.customer_name,
            start_date: this.startdate,
            end_date: this.enddate,
          }
        );
        response.data.map((item) => {
          item.time_detect = new Date(item.time_detect).toLocaleString();
          this.arrlastanomaly.push(item);
        });
        console.log(response.data);
        this.arrlastanomaly.sort(function(a, b) {
          return new Date(b.time_detect) - new Date(a.time_detect);
        });
        this.loadTable_anomaly = false;
        // console.log(this.arrlastanomaly);
      } catch (error) {
        console.error(error);
      }
    },
    async lastthreat() {
      this.arrlastthreat = [];
      this.loadTable_threat = true;
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/Dashboard/Lastdata_Threat/",
          {
            customer_name: this.customer_name,
            start_date: this.startdate,
            end_date: this.enddate,
          }
        );
        response.data.map((item) => {
          item.time_detect = new Date(item.time_detect).toLocaleString();
          this.arrlastthreat.push(item);
        });

        this.arrlastthreat.sort(function(a, b) {
          return new Date(b.time_detect) - new Date(a.time_detect);
        });
        this.loadTable_threat = false;
        // console.log(this.arrlastthreat);
      } catch (error) {
        console.error(error);
      }
    },
    async databargraph() {
      // var width = '100%'
      this.loading = true;
      this.arrgraphdate = [];
      this.arrgraphthreat = [];
      this.arrgraphanomaly = [];
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/Dashboard/CountforGraph/",
          {
            customer_name: this.customer_name,
            start_date: this.startdate,
            end_date: this.enddate,
          }
        );
        response.data.map((item) => {
          this.arrgraphdate.push(item.date);
          this.arrgraphthreat.push(item.threat);
          this.arrgraphanomaly.push(item.anomaly);
        });
        this.loading = false;
        // console.log("++++++++++++++++++++++++")
        // console.log(this.arrgraphdate);
        // console.log(this.arrgraphthreat);
        // console.log(this.arrgraphanomaly);
        this.option = {
          responsive: true,
          maintainAspectRatio: false,

          title: {
            text: "Threat & Anomaly / Date",
            textStyle: {
              color: "#FFFF",
            },
          },
          toolbox: {
            show: true,
            feature: {
              dataView: {
                show: true,
                readOnly: true,
                title: "Data view",
                lang: ["Data view", "close"],
              },
              magicType: {
                show: true,
                type: ["line", "bar"],
                title: {
                  line: "Line",
                  bar: "Bar",
                },
              },
              Dialog_charts: {
                show: true,
                title: "custom extension method 1",
                icon:
                  "path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891",
                onclick: function() {
                  alert("myToolHandler1");
                },
              },
            },
          },
          grid: {
            top: 50,
            bottom: 15,
            left: 5,
            right: 5,
            containLabel: true,
          },
          textStyle: {
            color: "#FFFF",
          },
          backgroundColor: "transparent",
          tooltip: {},
          legend: {
            data: ["Threat", "Anomaly"],
            textStyle: {
              color: "#FFFF",
            },
          },

          xAxis: [
            {
              type: "category",
              axisTick: {
                alignWithLabel: true,
              },
              axisLine: {
                lineStyle: {
                  color: "#ccc",
                },
              },
              axisLabel: { interval: 0, rotate: 90 },
              data: this.arrgraphdate,
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            {
              name: "Threat",
              type: "bar",
              barGap: 0,
              emphasis: {
                focus: "series",
              },
              data: this.arrgraphthreat,
            },
            {
              name: "Anomaly",
              type: "bar",
              emphasis: {
                focus: "series",
              },
              data: this.arrgraphanomaly,
            },
          ],

          // width: this.width,
        };
        console.log(this.option);
      } catch (error) {
        console.error(error);
      }
    },

    async dateFillter() {
      window.analytics.track(
        "[Dashbord][dateFillter]",
        {
          startdate: this.startdate,
          enddate: this.enddate,
        },
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
      this.arrgraphdate = [];
      this.arrgraphthreat = [];
      this.arrgraphanomaly = [];
      if (this.start == "" || this.end == "") {
        // this.startdate = Date.parse(new Date()) - 2592000000;
        this.startdate = Date.parse(new Date()) - 86400000;
        this.enddate = Date.parse(new Date());
        this.databargraph();
        this.countallanomalyandthreat();
        // this.compare_countallanomalyandthreat();
      } else {
        this.startdate = Date.parse(this.start);
        this.enddate = Date.parse(this.end);
        // console.log(this.startdate);
        // console.log(this.enddate);
        // console.log(this.start);
        // console.log(this.end);
        this.databargraph();
        this.countallanomalyandthreat();
        // this.compare_countallanomalyandthreat();
        this.lastanomaly();
        this.lastthreat();
      }
    },

    clearFillter() {
      window.analytics.track(
        "[Dashbord][clearFillter_Time]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
      this.arrgraphdate = [];
      this.arrgraphthreat = [];
      this.arrgraphanomaly = [];
      this.start = "";
      this.end = "";
      if (this.start == "" || this.end == "") {
        this.startdate = Date.parse(new Date()) - 86400000;
        this.enddate = Date.parse(new Date());
        this.databargraph();
        this.countallanomalyandthreat();
        this.compare_countallanomalyandthreat();
        this.lastanomaly();
        this.lastthreat();
      }
    },

    async linkilog(item) {
      // console.log(item);
      this.device_vendor = item.device_vendor;
      this.startdate = Date.parse(new Date()) - 86400000;
      this.enddate = Date.parse(new Date());
      // window.open('https://dev.ilog.ai/app/dashboard');
      // // window.location.href ="https://dev.ilog.ai/app/dashboard?PostData1=" + item.port + "&PostData2=" + PostData2;
      let response = await axios.post(
        "https://dev.ilog.ai:8003" + "/api/auth/requestAccess",
        {
          device: item.device_vendor,
          start: this.startdate,
          end: this.enddate,
          text: item.src_ip,
        }
      );
      console.log(response);
      window.open(response.data.url, "_blank");

      window.analytics.track(
        "[Dashbord][Drilldown_ilog]",
        {
          device: item.device_vendor,
          start: this.startdate,
          end: this.enddate,
          text: item.src_ip,
        },
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
