<template>
  <div class="wrapper">
    <p>
      Timeline test 2
    </p>
    <div id="visualization"></div>
  </div>
</template>

<script>
import { Timeline } from "vis-timeline/standalone";
// import jwtDecode from "jwt-decode";
import axios from "axios";
export default {
  data() {
    return {
      items: [],
      options: {
        height: "400px",
        align: "center",
        locale: "en",
        // moment: function(date) {
        //   return vis.moment(date).utcOffset('+07:00');
        // },
        // editable: {
        //                 add: true,         // add new items by double tapping
        //                 updateTime: true,  // drag items horizontally
        //                 updateGroup: true, // drag items from one group to another
        //                 remove: true,       // delete an item by tapping the delete button top right
        //                 overrideItems: false  // allow these options to override item.editable
        // },
        tooltip: {
          followMouse: true,
          overflowMethod: "cap",
        },
      },
      startdate: Date.parse(new Date()) - 2592000000,
      enddate: Date.parse(new Date()),
      dataLoaded: null,
      item : {}
    };
  },
  created() {
    // this.token = sessionStorage.getItem("user-token");
    // this.decoded = jwtDecode(this.token);

    // this._id = this.decoded._id;
    // this.username = this.decoded.username;
    // this.customer_name = this.decoded.customer_name;
    this.getallalert();

    // this.getLevel();
  },

  mounted() {
    // Create a Timeline
    // let timeline = new Timeline(document.getElementById("visualization"));
    // timeline.setOptions(this.options);
    // timeline.setItems(this.items);
  },
  methods: {
    async getallalert() {
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Alert/Show_Alert/",
          {
            customer_name: "ragnar",
            start_date: this.startdate,
            end_date: this.enddate,
          }
        );

      response.data.map((item_api) => {
        this.item = {};
        
        this.item.id = item_api._id;
        this.item.start = item_api.time;
        this.item.type = "point";
        this.item.title =
          "<span style='font-size:9px; margin:0; padding: 0 0 0 0'>" +
          item_api.category +
          "</span> <br> <p style='margin:0; padding: 0; line-height: 0.8;'>" +
          item_api.src_ip +
          "</p> <br> <p style='margin:0; padding: 0; line-height: 0.8;'>" +
          item_api.dev_name +
          "</p> <br> <p style='margin:0; padding: 0; line-height: 0.8;'>" +
          item_api.log_type +
          "</p> <br> <p style='margin:0; padding: 0; line-height: 0.8;'>" +
          item_api.level +
          "</p>";
        
        // console.log(item)
        this.items.push(this.item);
      });
        // var json = this.items
        // Array.observe(this.items, function(json) {
        //   console.log(json);
        // });
        console.log(this.items)

        let timeline = new Timeline(document.getElementById("visualization"));
        timeline.setOptions(this.options);
        timeline.setItems(this.items);

        
      } catch (error) {
        alert(error);
        console.error(error);
      }

      // this.dataLoaded = axios
      //   .post("https://backstage.v-insight.co:8001/api/alert/show_alert", {
      //     customer_name: "ragnar",
      //     start_date: this.startdate,
      //     end_date: this.enddate,
      //   })
      //   .then((res) => {
      //     res.data.forEach((item_api) => {
      //       let item = {};
      //       item.id = item_api._id;
      //       item.start = item_api.time;
      //       item.type = "point";
      //       item.title =
      //         "<span style='font-size:9px; margin:0; padding: 0 0 0 0'>" +
      //         item_api.category +
      //         "</span> <br> <p style='margin:0; padding: 0; line-height: 0.8;'>" +
      //         item_api.src_ip +
      //         "</p> <br> <p style='margin:0; padding: 0; line-height: 0.8;'>" +
      //         item_api.dev_name +
      //         "</p> <br> <p style='margin:0; padding: 0; line-height: 0.8;'>" +
      //         item_api.log_type +
      //         "</p> <br> <p style='margin:0; padding: 0; line-height: 0.8;'>" +
      //         item_api.level +
      //         "</p>";
      //       this.items.push(item);
      //     });
      //   });
      //   console.log(this.items)
    },
  },
};
</script>
<style></style>
