<template>
  <app id="device_analytic">
    <v-row>
      <v-col sm="2" md="auto" lg="auto">
        <h1 class="page-title">Device Analytic</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="2" md="auto" lg="auto">
        <h2 class="page-title">{{ $route.params.device_name }}</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col auto>
        <v-card>
          <v-tabs v-model="tab_index" show-arrows color="#eb1c25">
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <v-tab
              v-for="item in category"
              :key="item"
              @click.native="getField()"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="2" md="auto" lg="auto">
        <VueCtkDateTimePicker
          v-model="start"
          format="YYYY-MM-DD HH:mm"
          label="Select Time"
          :minute-interval="1"
          dark
        >
        </VueCtkDateTimePicker>
      </v-col>

      <v-col sm="2" md="auto" lg="auto">
        <h2>To</h2>
      </v-col>
      <v-col sm="2" md="auto" lg="auto">
        <VueCtkDateTimePicker
          v-model="end"
          format="YYYY-MM-DD HH:mm"
          label="Select Time"
          :minute-interval="1"
          dark
        >
        </VueCtkDateTimePicker>
      </v-col>
      <v-col sm="2" md="auto" lg="auto">
        <v-btn rounded color="#32CD32" class="mx-1" dark @click="dateFillter">
          SUBMIT
        </v-btn>

        <v-btn
          rounded
          color="#121212"
          class="mx-1"
          style="border: 1px solid #C7C7C7;"
          dark
          @click="clearFillter"
        >
          <v-icon>mdi-notification-clear-all</v-icon>
          <div style="font-weight: lighter;">CLEAR</div>
        </v-btn>
      </v-col>

      <v-spacer></v-spacer>

      <v-col sm="2" md="auto" lg="auto">
        <v-btn rounded color="#3D3D3D" dark>
          <v-icon>mdi-pdf-box</v-icon>
          <div style="text-transform: capitalize; font-weight: lighter;">
            Export Report PDF
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <!-- **********
      v-bind:category ="this.usecase"
      v-bind:startdate ="this.startdate"
      v-bind:enddate ="this.enddate"
      v-bind:device_name ="this.device_name"
    ></Test>
    *********!-->

    <v-row>
      <v-col cols="3">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title class="pa-6 pb-3">
                <p>Anomalies</p>
              </v-card-title>
              <v-card-text class="pa-6 pt-0">
                <v-row>
                  <v-col cols="auto">
                    <span class="" style="font-size: 42px"
                      >{{ this.countanomaly }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="auto">
                    <div v-if="countanomaly < old_countanomaly">
                      {{ compare_anomaly
                      }}<v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="success" v-bind="attrs" v-on="on">
                            mdi-arrow-bottom-right</v-icon
                          >
                        </template>
                        <span
                          >Anomaly has decreased {{ compare_anomaly }} from the
                          {{ time_compare }}
                        </span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="countanomaly > old_countanomaly">
                      {{ compare_anomaly
                      }}<v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="error" v-bind="attrs" v-on="on">
                            mdi-arrow-top-right</v-icon
                          >
                        </template>
                        <span
                          >Anomaly has increased {{ compare_anomaly }} from the
                          {{ time_compare }}
                        </span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="countanomaly == old_countanomaly">
                      {{ compare_anomaly
                      }}<v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="warning" v-bind="attrs" v-on="on">
                            mdi-approximately-equal</v-icon
                          >
                        </template>
                        <span>
                          Anomaly has equal {{ compare_anomaly }} from the
                          {{ time_compare }}
                        </span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title class="pa-6 pb-3">
                <p>Threat</p>
              </v-card-title>
              <v-card-text class="pa-6 pt-0">
                <v-row>
                  <v-col cols="auto">
                    <span class="" style="font-size: 42px"
                      >{{ this.countthreat }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="auto">
                    <div v-if="countthreat < old_countthreat">
                      {{ compare_threat }}
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="success" v-bind="attrs" v-on="on">
                            mdi-arrow-bottom-right</v-icon
                          >
                        </template>
                        <span>
                          Threat has decreased {{ compare_threat }} from the
                          {{ time_compare }}
                        </span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="countthreat > old_countthreat">
                      {{ compare_threat
                      }}<v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="error" v-bind="attrs" v-on="on">
                            mdi-arrow-top-right</v-icon
                          >
                        </template>
                        <span>
                          Threat has increased {{ compare_threat }} from the
                          {{ time_compare }}
                        </span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="countthreat == old_countthreat">
                      {{ compare_threat
                      }}<v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="warning" v-bind="attrs" v-on="on">
                            mdi-approximately-equal</v-icon
                          >
                        </template>
                        <span>
                          Threat has equal {{ compare_threat }} from the
                          {{ time_compare }}
                        </span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="9">
        <v-card>
          <div v-if="loading_line" class="d-flex justify-center align-center">
            <MoonLoader :color="'#54f1d2'"></MoonLoader>
          </div>
          <div v-else>
            <v-chart
              style="width: 100%"
              class="pa-6 pb-3"
              :autoresize="true"
              :options="anomalyoption"
            ></v-chart>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- *******************!-->
    <!-- *******************!-->
    <v-row>
      <v-col cols="12">
        <v-card height="500px">
          <div v-if="loading">
            <PulseLoader :color="'#54f1d2'" />
          </div>
          <div v-else>
            <v-chart
              :options="baroption"
              :autoresize="true"
              :style="{ height: height, width: width }"
              class="pa-6 pb-3"
            ></v-chart>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- *******************!-->
    <!-- *******************!-->

    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title style=" text-transform: capitalize; ">
            Threat ({{ this.category[this.tab_index] }})
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="arrlastthreat"
            :loading="loadTable"
          >
            <template v-slot:item.ilog="{ item }">
              <v-img
                class="mx-auto my-auto pointer"
                :src="logo_ilog"
                @click="linkilog(item)"
                width="18px"
                height="auto"
              >
              </v-img>
            </template>

            <v-progress-linear
              slot:progress
              color="blue"
              indeterminate
            ></v-progress-linear>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title style=" text-transform: capitalize; ">
            Anomaly ({{ this.category[this.tab_index] }})
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="arrlastanomaly"
            :loading="loadTable"
          >
            <template v-slot:item.ilog="{ item }">
              <v-img
                class="mx-auto my-auto pointer"
                :src="logo_ilog"
                @click="linkilog(item)"
                width="18px"
                height="auto"
              >
              </v-img>
            </template>

            <v-progress-linear
              slot:progress
              color="blue"
              indeterminate
            ></v-progress-linear>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </app>
</template>

<script>
//import {GridLayout, GridItem} from 'vue-grid-layout'
import jwtDecode from "jwt-decode";
import axios from "axios";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

//import Test from "../components/device_analytic/analytic_dashbord";

import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

//import Anomaly1 from '../components/test_anomaly1';
//import Anomaly1 from '../components/test_anomaly1';

export default {
  name: "device_analytic",
  components: {
    //  GridLayout,
    //  GridItem,
    "v-chart": ECharts,
    VueCtkDateTimePicker,
    MoonLoader,
    //Test,
  },
  data() {
    return {
      width: "100%",
      height: "500px",
      category: [],
      tab_index: 0,
      start: "",
      end: "",
      // startdate: Date.parse(new Date()) - 2592000000,
      // enddate: Date.parse(new Date()),
      startdate: Date.parse(new Date()) - 8640000,
      enddate: Date.parse(new Date()),
      time_compare: "",

      loading: false,
      loadTable: false,
      loading_line: false,

      countanomaly: "",
      countthreat: "",
      old_countanomaly: "",
      old_countthreat: "",
      compare_anomaly: "",
      compare_threat: "",

      usecase: "",

      arrlastanomaly: [],
      arrlastthreat: [],
      headers: [],

      arrbargraphdate: [],
      arrbargraphthreat: [],
      arrbargraphanomaly: [],

      baroption: {},
      anomalyoption: {},
      arranomalydate: [],
      arrprediction: [],
      arrlower_bound: [],
      arrupper_bound: [],
      arrreal_value: [],
    };
  },

  created() {
    this.logo_ilog = require("../assets/img/favicon.png");
    this.token = sessionStorage.getItem("user-token");
    this.decoded = jwtDecode(this.token);
    this.ampid = this.decoded.ampid;
    this._id = this.decoded._id;
    this.user_inst = this.decoded.instance;
    this.customer_name = this.decoded.customer_name;
    this.usecase = this.category[this.tab_index];
    this.device_name = this.$route.params.device_name;
    if (this.customer_name == undefined || this.device_name == undefined) {
      this.$router.replace({ name: "devices" });
    }

    this.getcategory();
    // this.getField()
    this.dataanomalygraph();
  },
  methods: {
    async getcategory() {
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/Device_Analytic/ShowDevice_Category/",
          {
            customer_name: this.customer_name,
            device_name: this.device_name,
            //device_name: "AECS_FW_Primary",
          }
        );

        response.data.map((item) => {
          this.category[0] = "all";
          this.category.push(item);
        });
        this.usecase = this.category[0];
        this.getField();
        this.getcountdevice_category();
        this.compare_countdevice_category();
        // this.databargraph();
        // this.lastanomaly();
        // this.lastthreat();
        this.loadTable = false;
      } catch (error) {
        console.error(error);
      }
    },

    async getcountdevice_category() {
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/Device_Analytic/CountDevice_Category/",
          {
            customer_name: this.customer_name,
            device_name: this.device_name,
            // device_name: "AECS_FW_Primary",
            usecase: this.usecase,
            start_date: this.startdate,
            end_date: this.enddate,
          }
        );
        // console.log(this.startdate)
        // console.log(this.enddate)
        this.countanomaly = response.data.anomaly;
        this.countthreat = response.data.threat;
        this.compare_countdevice_category();
      } catch (error) {
        console.error(error);
      }
    },

    async compare_countdevice_category() {
      var timerange = this.enddate - this.startdate;
      this.time_compare = this.dhm(timerange);
      // console.log(timerange);
      // console.log(this.startdate - timerange);
      // console.log(this.enddate - timerange);
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/Device_Analytic/CountDevice_Category/",
          {
            customer_name: this.customer_name,
            device_name: this.device_name,
            // device_name: "AECS_FW_Primary",
            usecase: this.usecase,
            start_date: this.startdate - timerange,
            end_date: this.enddate - timerange,
          }
        );

        this.old_countanomaly = response.data.anomaly;
        this.old_countthreat = response.data.threat;

        this.compare_anomaly = Math.abs(
          this.countanomaly - this.old_countanomaly
        );
        this.compare_threat = Math.abs(this.countthreat - this.old_countthreat);
      } catch (error) {
        alert(error);
        console.error(error);
      }
    },

    dhm(t) {
      var cd = 24 * 60 * 60 * 1000,
        ch = 60 * 60 * 1000,
        d = Math.floor(t / cd),
        h = Math.floor((t - d * cd) / ch),
        m = Math.round((t - d * cd - h * ch) / 60000),
        pad = function(n) {
          return n < 10 ? "0" + n : n;
        };
      if (m === 60) {
        h++;
        m = 0;
      }
      if (h === 24) {
        d++;
        h = 0;
      }
      return d + " Days " + pad(h) + " Hours " + pad(m) + " Minutes";
    },

    async lastanomaly() {
      this.loadTable = true;
      this.arrlastanomaly = [];
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/Device_Analytic/Last_Ten_Category_Anomaly/",
          {
            customer_name: this.customer_name,
            device_name: this.device_name,
            // device_name: "AECS_FW_Primary",
            usecase: this.usecase,
            start_date: this.startdate,
            end_date: this.enddate,
          }
        );
        response.data.map((item) => {
          item.time_detect = new Date(item.time_detect).toLocaleString();
          this.arrlastanomaly.push(item);
        });
        this.arrlastanomaly.sort(function(a, b) {
          return new Date(b.time_detect) - new Date(a.time_detect);
        });
        this.loadTable = false;
      } catch (error) {
        console.error(error);
      }
    },
    async lastthreat() {
      this.loadTable = true;
      // console.log(".................");
      // console.log(this.usecase);
      this.arrlastthreat = [];
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/Device_Analytic/Last_Ten_Category_Threat/",
          {
            customer_name: this.customer_name,
            device_name: this.device_name,
            // device_name: "AECS_FW_Primary",
            usecase: this.usecase,
            start_date: this.startdate,
            end_date: this.enddate,
          }
        );
        response.data.map((item) => {
          item.time_detect = new Date(item.time_detect).toLocaleString();
          this.arrlastthreat.push(item);
        });
        this.arrlastthreat.sort(function(a, b) {
          return new Date(b.time_detect) - new Date(a.time_detect);
        });
        // console.log(this.arrlastthreat);
        this.loadTable = false;
      } catch (error) {
        console.error(error);
      }
    },

    async getField() {
      // this.arrbargraphdate = [];
      this.arrbargraphthreat = [];
      this.arrbargraphanomaly = [];
      this.arrlastthreat = [];
      this.arrlastanomaly = [];
      this.usecase = this.category[this.tab_index];

      this.getcountdevice_category();
      this.lastanomaly();
      this.lastthreat();
      this.databargraph();

      switch (this.usecase) {
        case "insecure country":
          this.headers = [
            { text: "Date", value: "time_detect" },
            // {
            //   text: "Device name",
            //   value: "dev_name",
            // },
            { text: "Source Ip", value: "src_ip" },
            { text: "Destination Ip", value: "dest_ip" },
            { text: "Country", value: "fromsrcip_country" },
          ];
          break;
        case "insecure port":
          this.headers = [
            { text: "Date", value: "time_detect" },
            // {
            //   text: "Device name",
            //   value: "dev_name",
            // },
            { text: "Source Ip", value: "src_ip" },
            { text: "Destination Ip", value: "dest_ip" },
            { text: "Destination Port", value: "dest_port" },
          ];
          break;
        case "check port not in use":
          this.headers = [
            { text: "Date", value: "time_detect" },
            // {
            //   text: "Device name",
            //   value: "dev_name",
            // },
            { text: "Source Ip", value: "src_ip" },
            { text: "Destination Ip", value: "dest_ip" },
            { text: "Destination Port", value: "dest_port" },
          ];
          break;
        case "network device connect to internet":
          this.headers = [
            { text: "Date", value: "time_detect" },
            { text: "Source Ip", value: "src_ip" },
            { text: "Destination Ip", value: "dest_ip" },
            { text: "Destination Port", value: "dest_port" },
            { text: "Protocol", value: "protocol" },
          ];
          break;
        case "port scan":
          this.headers = [
            { text: "Date", value: "time_detect" },
            // {
            //   text: "Device name",
            //   value: "dev_name",
            // },
            { text: "Source Ip", value: "src_ip" },
            { text: "Destination Ip", value: "dest_ip" },
            { text: "Destination Port", value: "dest_port" },
          ];
          break;
        case "user retire":
          this.headers = [
            { text: "Date", value: "time_detect" },
            // {
            //   text: "Device name",
            //   value: "dev_name",
            // },
            { text: "Source Ip", value: "src_ip" },
            { text: "User", value: "user" },
          ];
          break;
        default:
          this.headers = [
            { text: "Date", value: "time_detect" },
            // {
            //   text: "Device name",
            //   value: "dev_name",
            // },
            { text: "Source Ip", value: "src_ip" },
            { text: "Destination Ip", value: "dest_ip" },
          ];
          break;
      }

      // if (this.usecase == "insecure country") {
      //   this.headers = [
      //     {
      //       text: "Device name",
      //       align: "start",
      //       sortable: false,
      //       value: "device_vendor",
      //     },
      //     { text: "country", value: "fromsrcip_country" },
      //     { text: "Date", value: "time" },
      //     { text: "ilog", value: "ilog", align: "center" },
      //   ];
      // } else if (this.usecase == "port scan") {
      //   this.headers = [
      //     {
      //       text: "Device name",
      //       align: "start",
      //       sortable: false,
      //       value: "device_vendor",
      //     },
      //     { text: "Destination Port", value: "dest_port" },
      //     { text: "Date", value: "time" },
      //     { text: "ilog", value: "ilog", align: "center" },
      //   ];
      // } else {
      //   this.headers = [
      //     {
      //       text: "Device name",
      //       align: "start",
      //       sortable: false,
      //       value: "device_vendor",
      //     },
      //     { text: "Case", value: "category" },
      //     { text: "Date", value: "time" },
      //     { text: "ilog", value: "ilog", align: "center" },
      //   ];
      // }

      // this.getcountdevice_category();
      // this.lastanomaly();
      // this.lastthreat();
      // this.databargraph();
      // this.dataanomalygraph();
      window.analytics.track(
        "[Device_Analytic][Tabs][Select_Usecase][" + this.usecase + "]",
        {
          usecase: this.usecase,
        },
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    async databargraph() {
      this.arrbargraphdate = [];
      this.loading = true;
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/Device_Analytic/CountforGraph_Usecase/",
          {
            customer_name: this.customer_name,
            // device_name: "AECS_FW_Primary",
            device_name: this.device_name,
            usecase: this.usecase,
            start_date: this.startdate,
            end_date: this.enddate,
          }
        );
        response.data.map((item) => {
          this.arrbargraphdate.push(item.date);
          this.arrbargraphthreat.push(item.threat);
          this.arrbargraphanomaly.push(item.anomaly);
        });
        // this.loading = false;
        this.baroption = {
          title: {
            text:
              "Threat & Anomaly / Date (" + this.category[this.tab_index] + ")",
            textStyle: {
              color: "#FFFF",
            },
          },
          grid: {
            top: 50,
            bottom: 15,
            left: 3,
            right: 3,
            containLabel: true,
          },
          textStyle: {
            color: "#FFFF",
          },
          backgroundColor: "transparent",
          tooltip: {},
          legend: {
            data: ["Threat", "Anomaly"],
            textStyle: {
              color: "#FFFF",
            },
          },
          xAxis: [
            {
              type: "category",
              axisTick: {
                alignWithLabel: true,
              },
              axisLine: {
                lineStyle: {
                  color: "#ccc",
                },
              },
              axisLabel: { interval: 0, rotate: 90 },
              data: this.arrbargraphdate,
            },
          ],
          yAxis: [
            {
              type: "value",
              splitNumber: 1,
            },
          ],
          series: [
            {
              name: "Threat",
              type: "bar",
              barGap: 0,
              emphasis: {
                focus: "series",
              },
              data: this.arrbargraphthreat,
            },
            {
              name: "Anomaly",
              type: "bar",
              emphasis: {
                focus: "series",
              },
              data: this.arrbargraphanomaly,
            },
          ],
        };
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },

    async dataanomalygraph() {
      this.loading_line = true;
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/Device_Analytic/Predict/",
          {
            customer_name: this.customer_name,
            device_name: this.device_name,
            // device_name: "AECS_FW_Primary",
            start_date: this.startdate,
            end_date: this.enddate,
          }
        );
        response.data.map((item) => {
          item.time = new Date(item.time).toLocaleString();
          this.arranomalydate.push(item.time);
          // this.arrprediction.push(item.mean);
          this.arrlower_bound.push(item.lower_bound);
          this.arrupper_bound.push(item.upper_bound);
          // this.arrreal_value.push(item.sd);
          this.arrreal_value.push(item.real);
        });

        this.anomalyoption = {
          title: {
            text: "Confidence Band",
            textStyle: {
              color: "#FFFF",
            },
            left: "center",
          },
          legend: {
            data: ["real"],
            left: "right",
            textStyle: {
              color: "#FFFF",
            },
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            top: 50,
            bottom: 15,
            left: 3,
            right: 3,
            containLabel: true,
          },
          textStyle: {
            color: "#FFFF",
          },
          xAxis: {
            type: "category",
            axisLine: {
              lineStyle: {
                color: "#ccc",
              },
            },
            data: this.arranomalydate,
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              name: "lower_bound",
              type: "line",
              itemStyle: {
                color: "#ccc",
              },
              lineStyle: {
                opacity: 3,
                color: "#ccc",
                type: "dashed",
              },
              // areaStyle: {
              //     color: '#ccc'
              // },
              data: this.arrlower_bound,

              symbol: "none",
            },
            {
              name: "upper_bound",
              type: "line",
              itemStyle: {
                color: "#ccc",
              },
              lineStyle: {
                opacity: 3,
                color: "#ccc",
                type: "dashed",
              },
              areaStyle: {
                color: "#ccc",
              },

              data: this.arrupper_bound,

              symbol: "none",
            },
            // {
            //   name: "prediction",
            //   type: "line",
            //   hoverAnimation: false,
            //   symbolSize: 6,
            //   // itemStyle: {
            //   //   color: "#333",
            //   // },
            //   // lineStyle: {
            //   //   color: "#eb1c25",
            //   // },
            //   showSymbol: false,
            //   data: this.arrprediction,
            // },
            {
              name: "real",
              type: "line",
              hoverAnimation: false,
              symbolSize: 6,
              // itemStyle: {
              //   color: "#333",
              // },
              // lineStyle: {
              //   color: "#eb1c25",
              // },
              showSymbol: false,
              data: this.arrreal_value,
            },
            // {
            //   name: "real_value",
            //   type: "line",
            //   hoverAnimation: false,
            //   symbolSize: 6,
            //   // itemStyle: {
            //   //   color: "#333",
            //   // },
            //   lineStyle: {
            //     color: "#eb1c25",
            //   },
            //   showSymbol: false,
            //   data: this.arrreal_value,
            // },
          ],
        };
        this.loading_line = false;
      } catch (error) {
        console.error(error);
      }
    },

    async dateFillter() {
      this.arrbargraphdate = [];
      this.arrbargraphthreat = [];
      this.arrbargraphanomaly = [];
      if (this.start == "" || this.end == "") {
        this.startdate = Date.parse(new Date()) - 2592000000;
        this.enddate = Date.parse(new Date());
        this.getField();
        //this.databargraph();
      } else {
        this.startdate = Date.parse(this.start);
        this.enddate = Date.parse(this.end);
        this.getField();

        //this.databargraph();
      }

      window.analytics.track(
        "[Device_Analytic][dateFillter]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    async clearFillter() {
      window.analytics.track(
        "[Device_Analytic][clearFillter_Time]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
      this.start = "";
      this.end = "";
      if (this.start == "" || this.end == "") {
        this.startdate = Date.parse(new Date()) - 2592000000;
        this.enddate = Date.parse(new Date());
        this.getField();
        //this.databargraph();
      }
    },

    async linkilog(item) {
      // console.log(item);
      this.device_vendor = item.device_vendor;
      this.startdate = Date.parse(new Date()) - 300000;
      this.enddate = Date.parse(new Date());
      // window.open('https://dev.ilog.ai/app/dashboard');
      // // window.location.href ="https://dev.ilog.ai/app/dashboard?PostData1=" + item.port + "&PostData2=" + PostData2;
      let response = await axios.post(
        "https://dev.ilog.ai:8003" + "/api/auth/requestAccess",
        {
          device: item.device_vendor,
          start: this.startdate,
          end: this.enddate,
          text: item.src_ip,
        }
      );
      console.log(response);
      window.open(response.data.url, "_blank");

      window.analytics.track(
        "[Device_Analytic][Drilldown_ilog]",
        {
          device: item.device_vendor,
          start: this.startdate,
          end: this.enddate,
          text: item.src_ip,
        },
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },
  },
};
</script>

<style scoped>
.v-tab {
  text-transform: capitalize;
}
.pointer {
  cursor: pointer;
}
</style>
