<template>
  <app id="devices">
    <v-row>
      <v-col sm="auto" md="auto" lg="auto">
        <h1 class="page-title">Device</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="4" sm="4" md="3" lg="auto">
        <v-select
          :items="arrtypedevices"
          label="Devices Type"
          v-model="devicestype"
          @change="select_typedevices(devicestype)"
          dense
          rounded
          outlined
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="col-auto">
        <v-card>
          <v-card-title>
            Devices
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="datatables"
            :search="search"
            :loading="loadTable"
          >
            <template v-slot:item.detail="{ item }">
              <v-icon small class="mr-2" @click="linkItem(item)">
                mdi-eye
              </v-icon>
            </template>

            <v-progress-linear
              slot:progress
              color="blue"
              indeterminate
            ></v-progress-linear>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </app>
</template>

<script>
import jwtDecode from "jwt-decode";

import axios from "axios";

export default {
  name: "devices",
  components: {},
  data() {
    return {
      showFillterData: false,
      search: '',
      datatables: [],
      arrtypedevices: [],
      loadTable: false,
      startdate: "",
      enddate: "",
      vinsight: "vinsight",
      devicestype: 'all',
      category: [],
    };
  },
  created() {
    this.token = sessionStorage.getItem("user-token");
    this.decoded = jwtDecode(this.token);
    this.ampid = this.decoded.ampid
    this._id = this.decoded._id;
    this.username = this.decoded.username;
    this.customer_name = this.decoded.customer_name;

    console.log("xxxxxxxxxxxxxxxx");
    console.log(this.customer_name);

    this.getalldevice();
    this.getcategory();
    // window.analytics.track('[Devices]', {}, {
    //     integrations: {
    //       Amplitude: {
    //         'session_id': this.ampid
    //       }
    //     }
    // })
  },
  computed: {
    headers() {
      return [
        { text: "DeviceName", value: "device_name" },
        {
          text: "DeviceType",
          value: "device_type",
          filter: (value) => {
            if (!this.devicestype) return true;
            if (value == this.devicestype) return value;
            if (this.devicestype == "all") {
              return true
            }
          },
        },
        { text: "Anomaly", value: "anomaly" },
        { text: "threat", value: "threat" },
        {
          text: "Detail",
          value: "detail",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    async getalldevice() {
      this.loadTable = true;
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Devices/Show_All_Devices/",
          {
            customer_name: this.customer_name,
          }
        );
        response.data.map((item) => {
          this.datatables.push(item);
        });
        response.data.map((item) => {
          this.arrtypedevices[0] = "all";
          this.arrtypedevices.push(item.device_type);
        });
        this.loadTable = false;
      } catch (error) {
        alert("Incorrect email");
        console.error(error);
      }
    },

    select_typedevices(devicestype){
    window.analytics.track('[Devices][Devicestype_Fillter]['+devicestype+']', {
      fillter_type : devicestype
    }, {
        integrations: {
          Amplitude: {
            'session_id': this.ampid
          }
        }
    })
    },

    linkItem(item) {
    window.analytics.track('[Devices][Drilldown_Devices]', {
      device_name : item.device_name,
      device_type : item.device_type
    }, {
        integrations: {
          Amplitude: {
            'session_id': this.ampid
          }
        }
    })
      this.$router.push({
        name: "device_analytic",
        params: {
          device_name: item.device_name,
        },
      });
    },
  },
};
</script>
