<template>
  <app id="alert_history">
    <v-row>
      <v-col sm="auto" md="auto" lg="auto">
        <h1 class="page-title">Alert history</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-container fill-height fluid class="text-center" pb-8>
            <v-row dense align="center" justify="center">
              <v-col>
                <v-card-text class="pb-0"
                  ><v-icon size="40">mdi-server-security</v-icon></v-card-text
                >
                <v-card-text class="pt-0">
                  Case
                </v-card-text>
                <v-card-text-title>
                  {{ usecase }}
                </v-card-text-title>
              </v-col>

              <v-col>
                <v-card-text class="pb-0"
                  ><v-icon size="40">mdi-bullhorn-outline</v-icon></v-card-text
                >
                <v-card-text class="pt-0">
                  Level
                </v-card-text>
                <v-card-text-title>
                  <v-btn
                    :color="getColor(level)"
                    style="width: 80px;"
                    small
                    rounded
                    dense
                    dark
                  >
                    <div
                      class="d-flex justify-center"
                      style="text-align: center"
                    >
                      {{ level }}
                    </div>
                  </v-btn>
                </v-card-text-title>
              </v-col>

              <v-col>
                <v-card-text class="pb-0"
                  ><v-icon size="40"
                    >mdi-message-text-clock-outline</v-icon
                  ></v-card-text
                >
                <v-card-text class="pt-0">
                  Date Time
                </v-card-text>
                <v-card-text-title>
                  {{ time_detect }}
                </v-card-text-title>
              </v-col>

              <v-col>
                <v-card-text class="pb-0"
                  ><v-icon size="40">mdi-cellphone-link</v-icon></v-card-text
                >
                <v-card-text class="pt-0">
                  Device
                </v-card-text>
                <v-card-text-title>
                  {{ device_name }}
                </v-card-text-title>
              </v-col>
              <v-col>
                <v-card-text class="pb-0"
                  ><v-icon size="40">mdi-lan-connect</v-icon></v-card-text
                >
                <v-card-text class="pt-0">
                  Source Ip
                </v-card-text>
                <v-card-text-title>
                  {{ src_ip }}
                </v-card-text-title>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-container>
            <v-card-text-title class=" text-transform ">
              <div class="pa-1 pb-2">Timeline</div>
            </v-card-text-title>
            <div class="wrapper">
              <div id="visualization"></div>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="col-auto">
        <v-card>
          <v-card-title>
            Alert list
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="datatables"
            :search="search"
            :loading="loadTable"
          >
            <template v-slot:item.level="{ item }">
              <v-btn
                :color="getColor(item.level)"
                style="width: 80px;"
                small
                rounded
                dense
                dark
              >
                <div class="d-flex justify-center" style="text-align: center">
                  {{ item.level }}
                </div>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </app>
</template>

<script>
import jwtDecode from "jwt-decode";
import axios from "axios";

// import Timeline from 'vue-visjs-timeline'
import { Timeline } from "vis-timeline/standalone";
// import Timeline from "vis-timeline/peer";
// import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
// import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

// import VueCtkDateTimePicker1 from "vue-ctk-date-time-picker";
// import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
// import VueNextTimeline from 'vue-next-timeline'

export default {
  name: "alert_history",
  components: {
    // VueCtkDateTimePicker,
    // VueNextTimeline
    // Timeline
    // Timeline,
  },

  data() {
    return {
      // user: "",
      // src_ip: "",
      startdate: Date.parse(new Date()) - 2592000000,
      enddate: Date.parse(new Date()),
      // arruseranomaly: [],
      // arruserthreat: [],
      // loadTable: false,
      // loading: false,
      items: [],
      options: {
        height: "400px",
        align: "center",
        locale: "en",
        // moment: function(date) {
        //   return vis.moment(date).utcOffset('+07:00');
        // },
        // editable: {
        //                 add: true,         // add new items by double tapping
        //                 updateTime: true,  // drag items horizontally
        //                 updateGroup: true, // drag items from one group to another
        //                 remove: true,       // delete an item by tapping the delete button top right
        //                 overrideItems: false  // allow these options to override item.editable
        // },
        tooltip: {
          followMouse: true,
          overflowMethod: "cap",
        },
      },
      item: {},
      datatables: [],
      loadTable: true,
    };
  },

  created() {
    this.token = sessionStorage.getItem("user-token");
    this.decoded = jwtDecode(this.token);
    this.ampid = this.decoded.ampid;
    this.customer_name = this.decoded.customer_name;
    this.usecase = this.$route.params.usecase;
    this.level = this.$route.params.level;
    this.time_detect = this.$route.params.time_detect;
    this.src_ip = this.$route.params.src_ip;
    this.device_vendor = this.$route.params.device_vendor;
    this.device_name = this.$route.params.device_name;
    (this.dest_ip = this.$route.params.dest_ip),
      (this.dest_port = this.$route.params.dest_port),
      (this.protocol = this.$route.params.protocol),
      (this.fromsrcip_country = this.$route.params.fromsrcip_country),
      // this.$route.params.user
      // console.log(this.usecase);
      // console.log(this.items);
      this.getallalert();
  },

  computed: {
    headers() {
      return [
        { text: "Date Time", value: "time_detect", sortable: true },
        { text: "Use Case", value: "usecase" },
        // { text: "Device", value: "device_vendor" },
        { text: "Device", value: "device_name" },
        { text: "Source Ip", value: "src_ip" },
        { text: "Destination Ip", value: "dest_ip" },

        { text: "Destination Port", value: "dest_port" },
        { text: "Protocol", value: "protocol" },
        // { text: "Country", value: "src_country" },
        { text: "Label", value: "label" },
        {
          text: "Level",
          value: "level",
          align: "center",
          // fliter: (value) => {
          //   // if (this.arrLevel[this.tab_Level]) return true;
          //   if (value == this.arrLevel[this.tab_Level]) return value;
          //   // if (value == this.select_level) return value;
          //   if (this.arrLevel[this.tab_Level] == "all") {
          //     return true
          //   }
          // },
        },
      ];
    },
  },

  methods: {
    async getallalert() {
      this.loadTable = true;
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Alert/Show_Alert/",
          {
            customer_name: this.customer_name,
            start_date: this.startdate,
            end_date: this.enddate,
          }
        );

        response.data.map((item_api) => {
          this.item = {};
          if (
            item_api.device_name == this.device_name &&
            item_api.src_ip == this.src_ip &&
            item_api.dest_ip == this.dest_ip
          ) {
            item_api.time_detect = new Date(
              item_api.time_detect
            ).toLocaleString();
            this.datatables.push(item_api);
            this.item.id = item_api._id;
            this.item.start = item_api.time_detect;
            // this.item.type = "point";
            this.item.content = item_api.label;
            this.item.title =
              "<span style='font-size:9px; margin:0; padding: 0 0 0 0'> Usecase : " +
              item_api.usecase +
              "</span> <br> <span style='margin:0; padding: 0; line-height: 0.8;'> Devicename : " +
              item_api.device_name +
              "</span> <br> <span style='margin:0; padding: 0; line-height: 0.8;'> Source Ip : " +
              item_api.src_ip +
              "</span> <br> <span style='margin:0; padding: 0; line-height: 0.8;'> Destination Ip : " +
              item_api.dest_ip +
              "</span> <br> <span  style='margin:0; padding: 0; line-height: 0.8;'>  Destination Port : " +
              item_api.dest_port +
              "</span> <br> <span style='margin:0; padding: 0; line-height: 0.8;'> Protocol : " +
              item_api.protocol +
              "</span> <br> <span  style='margin:0; padding: 0; line-height: 0.8;'> Aleat : " +
              item_api.label +
              "</span> <br> <span  style='margin:0; padding: 0; line-height: 0.8;'> Level : " +
              item_api.level +
              "</span> ";
            this.items.push(this.item);
          }
          // console.log(item)
          // this.items.push(this.item);
        });
        console.log(this.items);

        let timeline = new Timeline(document.getElementById("visualization"));
        timeline.setOptions(this.options);
        timeline.setItems(this.items);
        this.loadTable = false;
      } catch (error) {
        alert(error);
        console.error(error);
      }
    },

    getColor(level) {
      if (level == "high") return "#BC4D2A";
      else if (level == "medium") return "#C98536";
      else if (level == "low") return "#CFAC2E";
      else if (level == "all") return "blue";
    },
  },
};
</script>

<style>
text-transform {
  text-transform: capitalize;
}
</style>
