<template>
  <app id="add_asset">
    <v-row>
      <v-col sm="2" md="auto" lg="auto">
        <h1 class="page-title">Asset</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="col-auto">
        <v-card>
          <v-card-title>
            Asset list
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mx-3"
            ></v-text-field>

            <v-btn
              dark
              color="green"
              @click="(dialog = true), Open_Dialog_Add()"
            >
              <v-icon left>
                mdi-card-plus-outline
              </v-icon>
              Add
            </v-btn>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="datatables"
            :search="search"
            :loading="loadTable"
          >
            <template v-slot:item.severity="{ item }">
              <v-btn
                class="no_pointer"
                :color="getColor(item.severity)"
                style="width: 80px;"
                small
                rounded
                dense
                dark
              >
                {{ item.severity }}
              </v-btn>
            </template>


            <template v-slot:item.management="{ item }">
              <v-btn icon @click="(dialog_edit = true), geteditasset(item)"
                ><v-icon>mdi-monitor-edit</v-icon></v-btn
              >

              <v-btn icon @click="(dialog_del = true), getdelasset(item)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>

            <v-progress-linear
              slot:progress
              color="blue"
              indeterminate
            ></v-progress-linear>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!------------------------------------  -->

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Asset</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-container>
          <v-card-text>
            <v-text-field
              v-model="ip"
              label="IP"
              outlined
              clearable
              required
            ></v-text-field>

            <v-text-field
              v-model="device_name"
              label="DeviceName"
              outlined
              clearable
              required
            ></v-text-field>

            <v-combobox
              v-model="open_port"
              :search-input.sync="search_combobox"
              hide-selected
              label="Open Port"
              multiple
              persistent-hint
              small-chips
              outlined
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      "<strong>{{ search_combobox }}</strong
                      >Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>

            <v-radio-group v-model="severity" row>
              <template v-slot:label>
                <div><strong>Severity : </strong></div>
              </template>
              <v-radio label="high" color="#CB5C4A" value="high"></v-radio>
              <v-radio label="medium" color="#D39D54" value="medium"></v-radio>

              <v-radio label="low" color="#67B251" value="low"></v-radio>
            </v-radio-group>

            <v-text-field
              v-model="description"
              label="Description"
              outlined
              clearable
              required
            ></v-text-field>
          </v-card-text>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="(dialog = false), clear(), Close_Dialog_Add()"
          >
            Close
          </v-btn>
          <v-btn color="green darken-1" dark @click="addasset(), clear()">
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!------------------------------------  -->

    <v-dialog
      :retain-focus="false"
      v-model="dialog_del"
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Delete</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-container>
          <v-card-text>
            <v-text-field
              v-model="ip"
              label="IP"
              outlined
              clearable
              required
              disabled
            ></v-text-field>

            <v-text-field
              v-model="device_name"
              label="DeviceName"
              outlined
              clearable
              required
              disabled
            ></v-text-field>

            <v-combobox
              v-model="open_port"
              :search-input.sync="search_combobox"
              hide-selected
              label="Open Port"
              multiple
              persistent-hint
              small-chips
              outlined
              disabled
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      "<strong>{{ search_combobox }}</strong
                      >". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>

            <v-radio-group v-model="severity" row disabled>
              <template v-slot:label>
                <div><strong>Severity : </strong></div>
              </template>
              <v-radio label="high" color="#CB5C4A" value="high"></v-radio>
              <v-radio label="medium" color="#D39D54" value="medium"></v-radio>

              <v-radio label="low" color="#67B251" value="low"></v-radio>
            </v-radio-group>

            <v-text-field
              v-model="description"
              label="Description"
              outlined
              clearable
              required
              disabled
            ></v-text-field>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="(dialog_del = false), clear(), Close_Dialog_Delete()"
          >
            Close
          </v-btn>
          <v-btn
            color="#D9354B"
            dark
            @click="(dialog_del = false), delasset(), clear()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
      <!------------------------------------  -->
    </v-dialog>

    <v-dialog
      :retain-focus="false"
      v-model="dialog_edit"
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-container>
          <v-card-text>
            <v-text-field
              v-model="ip"
              label="IP"
              outlined
              clearable
              required
            ></v-text-field>

            <v-text-field
              v-model="device_name"
              label="DeviceName"
              outlined
              clearable
              required
            ></v-text-field>

            <v-combobox
              v-model="open_port"
              :search-input.sync="search_combobox"
              hide-selected
              label="Open Port"
              multiple
              persistent-hint
              small-chips
              outlined
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      "<strong>{{ search_combobox }}</strong
                      >". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>

            <v-radio-group v-model="severity" row>
              <template v-slot:label>
                <div><strong>Severity : </strong></div>
              </template>
              <v-radio label="high" color="#CB5C4A" value="high"></v-radio>
              <v-radio label="medium" color="#D39D54" value="medium"></v-radio>

              <v-radio label="low" color="#67B251" value="low"></v-radio>
            </v-radio-group>

            <v-text-field
              v-model="description"
              label="Description"
              outlined
              clearable
              required
            ></v-text-field>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="(dialog_edit = false), clear(), Close_Dialog_Edit()"
          >
            Close
          </v-btn>
          <v-btn
            color="#1888D8"
            dark
            @click="(dialog_edit = false), editasset(), clear()"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </app>
</template>

<script>
import jwtDecode from "jwt-decode";

import axios from "axios";

export default {
  name: "add_asset",
  components: {},
  data() {
    return {
      ip: "",
      device_name: "",
      severity: "",
      description: "",
      open_port: [],
      datatables: [],
      dialog: false,
      dialog_edit: false,
      dialog_del: false,
      search: "",
      addip: "",
      adddevice_name: "",
      addseverity: "",
      adddescription: "",
      addopen_port: "",
      // ip_edit: "",
      // hostname_edit: "",
      // severity_edit: "",
      // description_edit: "",
      // open_port_edit: "",
      arrdevice_name: [],
      arrip: [],
      search_combobox: "",
    };
  },
  created() {
    this.token = sessionStorage.getItem("user-token");
    this.decoded = jwtDecode(this.token);
    this.ampid = this.decoded.ampid;
    this._id = this.decoded._id;
    this.username = this.decoded.username;
    this.customer_name = this.decoded.customer_name;
    this.getasset();
  },
  computed: {
    headers() {
      return [
        // { text: "ID", value: "_id" },
        { text: "IP", value: "ip" },
        { text: "Hostname", value: "device_name" },
        { text: "Open port", value: "open_port" },
        {
          text: "Severity",
          value: "severity",
        },
        {
          text: "Management",
          value: "management",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    async getasset() {
      (this.ip = ""),
        (this.device_name = ""),
        (this.severity = ""),
        (this.description = ""),
        (this.open_port = ""),
        (this.loadTable = true);
      this.datatables = [];
      this.arrdevice_name = [];
      this.arrip = [];

      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Add_Asset/Show_Asset/",
          {
            customer_name: this.customer_name,
          }
        );
        response.data.map((item) => {
          this.datatables.push(item);
          this.arrdevice_name.push(item.device_name);
          this.arrip.push(item.ip);
        });
        // console.log(this.datatables)
        this.loadTable = false;
      } catch (error) {
        alert("Incorrect email");
        console.error(error);
      }
    },

    // comparator_hostname(arrhostnames){
    //   return arrhostnames === this.hostname

    // },
    // // comparator_ip(arrip){
    // //   return arrip === fruitToSearch;

    // // },

    // checksam() {
    // this.arrhostname.forEach(function(arrhostnames){
    //   if(this.comparator_hostname(arrhostnames)){
    //     return false;
    //   }else{
    //     return true;
    //   }
    // })
    // },
    Open_Dialog_Add() {
      window.analytics.track(
        "[Add_asset][Open_Dialog_Add][Asset]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },
    async addasset() {
      // let str_port = this.open_port;
      // console.log(str_port);
      // var arrport = str_port.split(","); // split string on comma space
      // console.log(arrport);

      // let json = {
      //   ip: this.ip,
      //   hostname: this.hostname,
      //   open_port: arrport,
      //   severity: this.severity,
      //   description: this.description,
      // };
      // console.log(json);
      // console.log(this.datatables);
      // console.log(this.arrhostname);
      // console.log(this.ip);
      // console.log(this.hostname);
      // // let x = this.datatables

      let check_device_name = this.datatables.some(
        (code) => code.device_name == this.device_name
      );
      let check_ip = this.datatables.some((code) => code.ip == this.ip);
      // console.log(check_hostname);
      // console.log(check_ip);
      if (!check_device_name && !check_ip) {
        let json = {
          ip: this.ip,
          device_name: this.device_name,
          open_port: this.open_port,
          severity: this.severity,
          description: this.description,
        };
        console.log(json);

        try {
          let response = await axios.post(
            "https://backstage.v-insight.co:8001" + "/api/Add_Asset/Add_Asset/",
            {
              customer_name: this.customer_name,
              data: json,
            }
          );
          let x = response.data;
          console.log(x);
          this.getasset();
          this.dialog = false;
          window.analytics.track(
            "[Add_asset][Add][Asset]",
            {
              customer_name: this.customer_name,
              data: json,
            },
            {
              integrations: {
                Amplitude: {
                  session_id: this.ampid,
                },
              },
            }
          );
        } catch (error) {
          alert("Incorrect email");
          console.error(error);
        }
        // alert("No_____sam");
      } else {
        alert("มี device_name หรือ ip นี้อยู่แล้ว");
      }
    },

    Close_Dialog_Add() {
      window.analytics.track(
        "[Add_asset][Close_Dialog_Add][Asset]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },
    getdelasset(item) {
      this.id = item._id;
      this.ip = item.ip;
      this.device_name = item.device_name;
      this.open_port = item.open_port;
      this.severity = item.severity;
      this.description = item.description;
      // console.log(this.id);
      // console.log(this.ip);
      // console.log(this.hostname);
      // console.log(this.open_port);
      // console.log(this.severity);
      window.analytics.track(
        "[Add_asset][Open_Dialog_Delete][Asset]",
        {
          id: item._id,
          ip: item.ip,
          device_name: item.device_name,
          open_port: item.open_port,
          severity: item.severity,
          description: item.description,
        },
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    async delasset() {
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/Add_Asset/Delete_Asset/",
          {
            customer_name: this.customer_name,
            id: this.id,
          }
        );
        let x = response.data;
        console.log(x);
        this.getasset();
        window.analytics.track(
          "[Add_asset][Delete][Asset]",
          {
            customer_name: this.customer_name,
            id: this.id,
          },
          {
            integrations: {
              Amplitude: {
                session_id: this.ampid,
              },
            },
          }
        );
      } catch (error) {
        alert("Incorrect email");
        console.error(error);
      }
    },

    Close_Dialog_Delete() {
      window.analytics.track(
        "[Add_asset][Close_Dialog_Delete][Asset]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    Close_Dialog_Edit() {
      window.analytics.track(
        "[Add_asset][Close_Dialog_Edit][Asset]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    geteditasset(item) {
      this.id = item._id;
      this.ip = item.ip;
      this.device_name = item.device_name;
      this.open_port = item.open_port;
      this.severity = item.severity;
      this.description = item.description;
      // console.log(this.id);
      // console.log(this.ip);
      // console.log(this.hostname);
      // console.log(this.open_port);
      // console.log(this.severity);
      window.analytics.track(
        "[Add_asset][Open_Dialog_Edit][Asset]",
        {
          id: item._id,
          ip: item.ip,
          device_name: item.device_name,
          open_port: item.open_port,
          severity: item.severity,
          description: item.description,
        },
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    async editasset() {
      let json = {
        ip: this.ip,
        device_name: this.device_name,
        open_port: this.open_port,
        severity: this.severity,
        description: this.description,
      };
      console.log(json);
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Add_Asset/Edit_Asset/",
          {
            customer_name: this.customer_name,
            id: this.id,
            data: json,
          }
        );
        let x = response.data;
        console.log(x);
        this.getasset();
        this.dialog_edit = false;
        window.analytics.track(
          "[Add_asset][Edit][Asset]",
          {
            customer_name: this.customer_name,
            id: this.id,
            data: json,
          },
          {
            integrations: {
              Amplitude: {
                session_id: this.ampid,
              },
            },
          }
        );
      } catch (error) {
        alert("Incorrect email");
        console.error(error);
      }
    },

    getColor(severity) {
      if (severity == "high") return "#CB5C4A";
      else if (severity == "medium") return "#D39D54";
      else if (severity == "low") return "#67B251";
    },

    clear() {
      this.ip = "";
      this.device_name = "";
      this.severity = "";
      this.description = "";
      this.open_port = "";
    },
  },
};
</script>
<style>
.no_pointer {
  cursor: initial;
}
</style>
