<template>
  <app id="whitelist">
    <v-row>
      <v-col sm="auto" md="auto" lg="auto">
        <h1 class="page-title">whitelist</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            whitelist
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mx-3"
            ></v-text-field>

          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="whitelist"
            :search="search"
            :loading="loadTable"
          >

            <template v-slot:item.management="{ item }">
              <v-btn
                icon
                @click="linkItem(item)"
                ><v-icon>mdi-dots-horizontal</v-icon></v-btn
              >

            </template>

            <v-progress-linear
              slot:progress
              color="blue"
              indeterminate
            ></v-progress-linear>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

  </app>
</template>

<script>
import jwtDecode from "jwt-decode";

import axios from "axios";


export default {
  name: "whitelist",
  components: {

  },
  data() {
    return {
      whitelist: [],
      loadTable: false,

    };
  },


  created() {
    this.logo_ilog = require("../assets/img/favicon.png");
    this.token = sessionStorage.getItem("user-token");
    this.decoded = jwtDecode(this.token);
    this.ampid = this.decoded.ampid
    // this._id = this.decoded._id;
    this.customer_name = this.decoded.customer_name;
    this.get_whitelist();

    // this.getLevel();
  },
  computed: {
    headers() {
      return [
        // { text: "Date Time", value: "time", sortable: true },
        { text: "Usecase", value: "usecase" },
        { text: "Device name", value: "device_name" },
        {
          text: "Management",
          value: "management",
          align: "center",
        },
      ];
    },
  },

  // watch: {

  // },

  methods: {

    async get_whitelist() {
      this.whitelist = [];
      this.loadTable = true;
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/WhiteList/Show_Usecase_for_WhiteList/",
          {
            customer_name: this.customer_name,
          }
        );

        response.data.map((item) => {
          this.whitelist.push(item);
        });
        console.log(this.whitelist);

        this.loadTable = false;
      } catch (error) {
        // alert("getallalert");
        // alert(error);
        console.error(error);
      }
    },

    getItem(item) {
      // console.log("++++++++++++")
      // console.log(this.customer_name)
      // console.log(item.device_vendor)
      // console.log(item.stage)
      // console.log(item._id)
      this.time = item.time;
      this.level = item.level;
      this.device_vendor = item.device_vendor;
      this.src_ip = item.src_ip;
      this.usecase = item.usecase;
      this.id = item._id;
      this.stage = item.stage;
      // this.check = this.stage;
      // console.log(this.level);
      // console.log(this.stage);
      // console.log(this.id);
      window.analytics.track('[Whitelist][Select_Management]', {
        time : item.time,
        level : item.level,
        device_vendor : item.device_vendor,
        src_ip : item.src_ip,
        usecase : item.usecase,
        id : item._id,
        stage : item.stage,
        }, {
        integrations: {
          Amplitude: {
            'session_id': this.ampid
          }
        }
      })
    },
    linkItem(item) {
      this.$router.push({
        name: "whitelist_setting",
        params: {
          device_name: item.device_name,
          usecase: item.usecase,
        },
      });

    window.analytics.track('[Whitelist][Drilldown_Whitelist]', {
      device_name : item.device_name,
      usecase: item.usecase,
    }, {
        integrations: {
          Amplitude: {
            'session_id': this.ampid
          }
        }
    })
    },
  },
};
</script>
<style scoped>
.v-tab {
  text-transform: capitalize;
}

.pointer {
  cursor: pointer;
}

.no_pointer {
  cursor: initial;
}

</style>