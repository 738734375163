<template>
  <app id="add_asset">
    <v-row>
      <v-col sm="2" md="auto" lg="auto">
        <h1 class="page-title">ML</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title
            class="headline font-weight-regular blue-grey white--text"
          >
            ML
          </v-card-title>
          <v-row>
            <v-col cols="12">
              <v-card-text>
                <v-text-field
                  v-model="usecase"
                  label="IP"
                  outlined
                  clearable
                ></v-text-field>

                <v-text-field
                  v-model="usecase"
                  label="IP"
                  outlined
                  clearable
                ></v-text-field>

              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </app>
</template>

<script>
import jwtDecode from "jwt-decode";

import axios from "axios";

export default {
  name: "add_asset",
  components: {},
  data() {
    return {
      ip: '',
      hostname: '',
    };
  },
  created() {
    this.token = sessionStorage.getItem("user-token");
    this.decoded = jwtDecode(this.token);

    this._id = this.decoded._id;
    this.username = this.decoded.username;
    this.customer_name = this.decoded.customer_name;
  },

  methods: {
    async addasset() {
      let str_port = this.open_port;
      console.log(str_port);
      var arrport = str_port.split(','); // split string on comma space
      console.log(arrport);

      let json = {
        ip: this.ip,
        hostname: this.hostname,
        port: arrport,
        severity: this.severity,
        description: this.description,
      };
      console.log(json);

      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/add/edit_MLParameter",
          {
            customer_name: this.customer_name,
            customer_details: "test",
            data: json,
          }
        );
        let x = response.data;
        console.log(x);
      } catch (error) {
        alert("Incorrect email");
        console.error(error);
      }
    },
  },
};
</script>
