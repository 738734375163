import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import layout from '../views/layout'
import dashboard from '../views/Dashbord.vue'
import devices from '../views/Devices.vue'
import users from '../views/Users.vue'
import alert from '../views/Alert.vue'
import setting from '../views/Setting.vue'
import notification from '../views/Notification.vue'
import device_analytic from '../views/Device_Analytic.vue'
import email_setting from '../views/Email_setting.vue'
import add_asset from '../views/Add_asset.vue'
import add_users from '../views/Add_users.vue'
import ml from '../views/Ml.vue'
import user_analytic from '../views/User_analytic.vue'
import alert_history from '../views/Alert_history.vue'
import whitelist from '../views/Whitelist.vue'
import whitelist_setting from '../views/Whitelist_Setting.vue'

import test from '../views/test.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/app',
    name: 'layout',
    component: layout,
    meta: {
      requiresAuth: true,
    },
    props: true,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: dashboard,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: 'devices',
        name: 'devices',
        component: devices,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: 'device_analytic',
        name: 'device_analytic',
        component: device_analytic,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'email_setting',
        name: 'email_setting',
        component: email_setting,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'users',
        name: 'users',
        component: users,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: 'user_analytic',
        name: 'user_analytic',
        component: user_analytic,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: 'alert',
        name: 'alert',
        component: alert,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: 'alert_history',
        name: 'alert_history',
        component: alert_history,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: 'whitelist',
        name: 'whitelist',
        component: whitelist,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: 'whitelist_setting',
        name: 'whitelist_setting',
        component: whitelist_setting,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: 'setting',
        name: 'setting',
        component: setting,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: 'notification',
        name: 'notification',
        component: notification,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: 'add_asset',
        name: 'add_asset',
        component: add_asset,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: 'add_users',
        name: 'add_users',
        component: add_users,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: 'ml',
        name: 'ml',
        component: ml,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: 'add_users',
        name: 'add_users',
        component: add_users,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: 'test',
        name: 'test',
        component: test,
        meta: {
          requiresAuth: true,
        },
        props: true
      },

    ]
  },
  // {
  //   path: '/device_analytic',
  //   name: 'device_analytic',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Device_Analytic.vue')
  // },

  
]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!sessionStorage.getItem('user-token')) {
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }
})


export default router
