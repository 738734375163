import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from "axios";

// import vueScrollto from 'vue-scrollto'
//import BootstrapVue from 'bootstrap-vue'

import './styles/app.scss'

// Vue.use(vueScrollto)

//Vue.use(BootstrapVue)

//import firebase from "firebase/app";

Vue.prototype.$axios = axios;

// import ECharts from 'vue-echarts' // refers to components/ECharts.vue in webpack

// import ECharts modules manually to reduce bundle size
// import 'echarts/lib/chart/bar'
// import 'echarts/lib/chart/line'
// import 'echarts/lib/component/polar'
// import 'echarts/lib/component/tooltip'

// import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
// import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

// Vue.component('chart', ECharts);

// Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

Vue.config.productionTip = false

// const firebaseConfig = {
//   apiKey: "AIzaSyClzsm5n89rFxPGGjHLFFhcJUjozWcUnaM",
//   authDomain: "v-insight-83291.firebaseapp.com",
//   databaseURL: "https://v-insight-83291-default-rtdb.firebaseio.com",
//   projectId: "v-insight-83291",
//   storageBucket: "v-insight-83291.appspot.com",
//   messagingSenderId: "785180538200",
//   appId: "1:785180538200:web:8569174448455ab30d612d",
//   measurementId: "G-J0KDE33DSN"
// };
// // Initialize Firebase
// firebase.initializeApp(firebaseConfig);


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
