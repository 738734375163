<template>
  <app id="whitelist_setting">
    <v-row>
      <v-col sm="auto" md="auto" lg="auto">
        <h1 class="page-title">whitelist setting</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col auto>
        <v-card>
          <v-tabs v-model="tab_whitelist_category" show-arrows color="#eb1c25">
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <v-tab
              v-for="item in arr_whitelist_category"
              :key="item"
              @click.native="get_whitelist_category()"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            whitelist
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mx-3"
            ></v-text-field>

          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="whitelist"
            :search="search"
            :loading="loadTable"
          >

            <template v-slot:item.management="{ item }">
              <v-btn
                icon
                @click="linkItem(item)"
                ><v-icon>mdi-dots-horizontal</v-icon></v-btn
              >

            </template>

            <v-progress-linear
              slot:progress
              color="blue"
              indeterminate
            ></v-progress-linear>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

  </app>
</template>

<script>
import jwtDecode from "jwt-decode";

import axios from "axios";


export default {
  name: "whitelist_setting",
  components: {

  },
  data() {
    return {
      whitelist_category:'',
      arr_whitelist_category: [],
      table_whitelist: [],
      whitelist: [],
      loadTable: false,

    };
  },


  created() {
    // this.logo_ilog = require("../assets/img/favicon.png");
    this.token = sessionStorage.getItem("user-token");
    this.decoded = jwtDecode(this.token);
    this.ampid = this.decoded.ampid
    // this._id = this.decoded._id;
    this.customer_name = this.decoded.customer_name;
    this.device_name = this.$route.params.device_name;
    this.usecase = this.$route.params.usecase;
    this.get_tab_whitelist();
    // this.get_whitelist_category();

    // this.getLevel();
  },
  computed: {

  },

  // watch: {

  // },

  methods: {

    async get_tab_whitelist() {
      this.arr_whitelist_category = [];
      var obj_whitelist_category = []
      this.loadTable = true;
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/WhiteList/Show_WhiteList/",
          {
            customer_name: this.customer_name,
            device_name: this.device_name,
            usecase: this.usecase,
          }
        );

        response.data.map((item) => {
          obj_whitelist_category.push(item);
        });
        
        var new_arr_whitelist_category = Object.keys(obj_whitelist_category[0])

        for (var i = new_arr_whitelist_category.length; i--;) {
          // if(new_arr_whitelist_category[i]!= "_id" && new_arr_whitelist_category[i] != "device_name" && new_arr_whitelist_category[i]!= "usecase"){
            this.arr_whitelist_category.push((new_arr_whitelist_category[i].toString()).replaceAll('_',' ')) 
          // }
        }
        // if(Object.keys(obj_whitelist_category[0])!= "_id"&& Object.keys(obj_whitelist_category[0]) != "device_name"&& Object.keys(obj_whitelist_category[0])!= "usecase"){
        //   this.arr_whitelist_category = Object.keys(obj_whitelist_category[0])
        // }
        
        // console.log(obj_whitelist_category);
        // console.log(this.arr_whitelist_category);

        this.loadTable = false;
        this.whitelist_category = (this.arr_whitelist_category[0].toString()).replaceAll(' ','_');
        this.get_whitelist() 

      } catch (error) {
        // alert("getallalert");
        // alert(error);
        console.error(error);
      }
    },
    get_whitelist_category() {
      this.whitelist_category = (this.arr_whitelist_category[this.tab_whitelist_category].toString()).replaceAll(' ','_');
      this.get_whitelist();
      // window.analytics.track('[Alert][Tabs][Select_Level]['+this.select_level+']', {
      //   level : this.select_level
      // }, {
      //   integrations: {
      //     Amplitude: {
      //       'session_id': this.ampid
      //     }
      //   }
      // })
    },
    async get_whitelist(){
      var obj_whitelist = []
      this.whitelist = []
      // var whitelist_category = this.whitelist_category
      console.log(this.whitelist_category);
      this.loadTable = true;
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/WhiteList/Show_WhiteList/",
          {
            customer_name: this.customer_name,
            device_name: this.device_name,
            usecase: this.usecase,
          }
        );
        // console.log(item[this.whitelist_category])  
        console.log(response)   
              response.data.map((item) => {
                obj_whitelist.push(item[this.whitelist_category]);
                // console.log("item.$this.whitelist_category")  
                // console.log(item.$this.whitelist_category) 
                // console.log(item[this.whitelist_category])   
              });

        // this.whitelist = Object.keys(obj_whitelist[0])
        // for (var i = this.whitelist.length; i--;) {
        //   if(this.whitelist[i]!= "_id" && new_arr_whitelist_category[i] != "device_name" && new_arr_whitelist_category[i]!= "usecase"){
        //     this.arr_whitelist_category.push(new_arr_whitelist_category[i]) 
        //   }
        // }

        // this.headers = [
        //       { text: "IP", value: "ip", sortable: true },
        //       { text: "Modified date", value: "modified_date" },
        //               {
        //   text: "Management",
        //   value: "management",
        //   align: "center",
        // },

        switch (this.whitelist_category) {
          case "ip_whitelist":
          this.headers = [
              { text: "IP", value: "ip", sortable: true },
              { text: "Modified date", value: "modified_date" },
                      {
          text: "Management",
          value: "management",
          align: "center",
        },
            ];
            break;
          case "port_whitelist":
            this.headers = [
              { text: "Port", value: "port", sortable: true },
              { text: "Modified date", value: "modified_date" },
                      {
          text: "Management",
          value: "management",
          align: "center",
        },
            ];
            break;
          case "country_whitelist":
            this.headers = [
              { text: "Country", value: "country", sortable: true },
              { text: "Modified date", value: "modified_date" },
                      {
          text: "Management",
          value: "management",
          align: "center",
        },
            ];
            break;
          case "user_whitelist":
            this.headers = [
              { text: "User", value: "user", sortable: true },
              { text: "Modified date", value: "modified_date" },
                      {
          text: "Management",
          value: "management",
          align: "center",
        },
            ];
            break;
          case "device_name_whitelist":
            this.headers = [
              { text: "Device", value: "device_name", sortable: true },
              { text: "Modified date", value: "modified_date" },
                      {
          text: "Management",
          value: "management",
          align: "center",
        },
            ];
            break;
          default:
            break;
        }

        // response.data.map((item) => {
        //   obj_whitelist.push(item);
        // });
        
        
        // this.whitelist = obj_whitelist[0]
        obj_whitelist[0].map((item) => {
          item.modified_date = new Date(item.modified_date).toLocaleString();
          this.whitelist.push(item);
        });

        // console.log("000");
        // console.log(obj_whitelist);
        // console.log(obj_whitelist[0]);
        // Object.keys(whitelist)
        // console.log(Object.keys(this.whitelist[0]))

        // this.whitelist.map((item) => {

        //   if(Object.keys(obj_whitelist[0])!= "_id" && new_arr_whitelist_category[i] != "device_name" && new_arr_whitelist_category[i]!= "usecase"){
        //     whitelist.push(item[this.whitelist_category]);
        //   }
        //         obj_whitelist.push(item[this.whitelist_category]);
        //         // console.log("item.$this.whitelist_category")  
        //         // console.log(item.$this.whitelist_category) 
        //         console.log(item[this.whitelist_category])   

        // });

        // for (var i = this.whitelist.length; i--;) {
        //   if(Object.keys(obj_whitelist[0])!= "_id" && new_arr_whitelist_category[i] != "device_name" && new_arr_whitelist_category[i]!= "usecase"){
        //     this.arr_whitelist_category.push(new_arr_whitelist_category[i]) 
        //   }
        // }


        this.loadTable = false;
      } catch (error) {
        // alert("getallalert");
        // alert(error);
        console.error(error);
      }
    
    },

    // async whitelist() {
    //   // this.user = ''
    //   // this.privilege = ''
    //   // this.description = ''
    //   // this.device_name = ''
    //   let json = {
    //     user: this.user,
    //     device_name: this.user_in_asset,
    //   };
    //   console.log(json);

    //   try {
    //     let response = await axios.post(
    //       "https://backstage.v-insight.co:8001" + "/api/add_user/add_user/",
    //       {
    //         customer_name: this.customer_name,
    //         data: json,
    //       }
    //     );
    //     let x = response.data;
    //     console.log(x);
    //     this.getusers();
    //     window.analytics.track(
    //       "[Add_users][Add][User]",
    //       {},
    //       {
    //         integrations: {
    //           Amplitude: {
    //             session_id: this.ampid,
    //           },
    //         },
    //       }
    //     );
    //     // this.dialog = false;
    //   } catch (error) {
    //     alert("Incorrect email");
    //     console.error(error);
    //   }
    // },

    // getItem(item) {
    //   // console.log("++++++++++++")
    //   // console.log(this.customer_name)
    //   // console.log(item.device_vendor)
    //   // console.log(item.stage)
    //   // console.log(item._id)
    //   this.time = item.time;
    //   this.level = item.level;
    //   this.device_vendor = item.device_vendor;
    //   this.src_ip = item.src_ip;
    //   this.usecase = item.usecase;
    //   this.id = item._id;
    //   this.stage = item.stage;
    //   // this.check = this.stage;
    //   console.log(this.level);
    //   console.log(this.stage);
    //   console.log(this.id);
    //   window.analytics.track('[Alert][Select_Management]', {
    //     time : item.time,
    //     level : item.level,
    //     device_vendor : item.device_vendor,
    //     src_ip : item.src_ip,
    //     usecase : item.usecase,
    //     id : item._id,
    //     stage : item.stage,
    //     }, {
    //     integrations: {
    //       Amplitude: {
    //         'session_id': this.ampid
    //       }
    //     }
    //   })
    // },
  },
};
</script>
<style scoped>
.v-tab {
  text-transform: capitalize;
}
</style>