<template>
  <v-app id="layout">

<!-- Top Bar Menu -->
    <v-app-bar app dense>
      <v-app-bar-nav-icon @click="drawer = !drawer,Menu(drawer)"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <v-toolbar-title>{{username}}</v-toolbar-title>
      
      <v-tooltip bottom>
        <v-btn icon slot="activator">
          <v-icon @click="logout">mdi-export</v-icon>
        </v-btn>
      </v-tooltip>



    </v-app-bar>

<!-- Side Bar Menu -->
    <v-navigation-drawer v-model="drawer" app >

        <v-list-item>
          <v-list-item-content>

          <v-list-item-title class="d-flex justify-center align-center " >
             <img :src="logo_vinsight"
                width="50"
                height="auto"
                class="responsive pr-2">
                <span class="title"> V-INSIGHT </span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>



      <v-list
        dense
        rounded
      >

        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              Main
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="item in main"
          :key="item.title"
          link
          router-link :to=item.name
          @click.native="getpage(item.title)"
          class="pl-10"
        >       
        
          <v-list-item-content>

          <v-list-item-title><v-icon class="pr-3">{{ item.icon }}</v-icon>{{ item.title }}</v-list-item-title>

          </v-list-item-content>
          
        </v-list-item>


        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              Alert
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

                <v-list-item
          v-for="item in alert"
          :key="item.title"
          link
          router-link :to=item.name
          @click.native="getpage(item.title)"
          class="pl-10"
        >       
          <v-list-item-content>

          <v-list-item-title><v-icon class="pr-3">{{ item.icon }}</v-icon>{{ item.title }}</v-list-item-title>

          </v-list-item-content>
          
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              Manage
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="item in manage"
          :key="item.title"
          link
          router-link :to=item.name
          @click.native="getpage(item.title)"
          class="pl-10"
        >       
          <v-list-item-content>

          <v-list-item-title><v-icon class="pr-3">{{ item.icon }}</v-icon>{{ item.title }}</v-list-item-title>

          </v-list-item-content>
          
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

      <v-main>

        <v-container > 
         
          <router-view>
          </router-view>

        </v-container>
        
      </v-main>

</v-app>
</template>

<script>
import axios from 'axios'
import jwtDecode from 'jwt-decode'


export default {
  name: 'layout',
  components: {


  },
  props: {
    isExpired: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sidebar: [],
      customer_name:"",
      main: [
                { title: 'Dashboard',name:'dashboard', icon: ' mdi-view-dashboard', },
                { title: 'Devices', name:'devices',icon: 'mdi-cellphone-link' } ,
                { title: 'User', name:'users',icon: 'mdi-account-multiple' } ,

                // { title: 'Test', name:'notification',icon: 'mdi-bell' } ,
                // { title: 'ML', name:'ml',icon: 'mdi-transit-connection-variant' } 
      ],
      alert: [
                { title: 'Alert', name:'alert',icon: 'mdi-alert-circle' } ,
                { title: 'Whitelist', name:'whitelist',icon: 'mdi-view-list-outline' } ,
                { title: 'Email Setting', name:'email_setting',icon: 'mdi-email-outline' } ,
      ],
      manage: [
                { title: 'Setting', name:'setting',icon: 'mdi-cog' } ,
                { title: 'Add Asset', name:'add_asset',icon: 'mdi-server-plus' } ,
                { title: 'Add Users', name:'add_users',icon: 'mdi-account-multiple-plus' } ,
                { title: 'Test', name:'test',icon: 'mdi-bell' } ,
      ],
      token: '',
      message: '',
      drawer: false,
      failureClass: 'red darken-4',
      successClass: 'white',
      tcca: true,
      badgeNoti: 0,
      role: '',
      names: [],
      showDialogTerminal: false,
      decoded: '',
      username: '' ,
      ampid:'',
      authorization:false
    }
  },
  async created () {
    this.logo_vinsight = require('../assets/img/V-SIGHT_logo-03.png')
    this.token = sessionStorage.getItem('user-token')
    this.decoded = jwtDecode(this.token)
    this.ampid = this.decoded.ampid
    axios.defaults.headers.common['Authorization'] = this.token
    this.username = this.decoded.username
    this.customer_name = this.decoded.customer_name
    console.log(this.authorization)

    try {
      // let response = await axios.get('https://backstage.v-insight.co:8001'+ '/api/auth/layout/')
      let response = await axios.post(
            "https://backstage.v-insight.co:8001" + "/api/Check_Customer_in_DB/",
            {
              customer_name: this.customer_name,
            }
      );
      this.authorization = response.data.Authorization
      // console.log(this.authorization)
      // if(!this.authorization){
      //  this.$router.replace({ name: 'login' }) 
      // }
    } catch (error) {
      this.message = 'Unauthorized Access'
      this.$router.replace({ name: 'login' })
    }  
  },
  methods: {
    logout () {
      sessionStorage.removeItem('user-token')
      this.$router.replace({ name: 'login' })
      window.analytics.track('[LogOut]', {}, {
        integrations: {
          Amplitude: {
            'session_id': this.ampid
          }
        }
      })
      window.analytics.reset()

    },
    contact () {
      window.open('https://www.ragnar.co.th')
    },
    Menu(drawer){
      if(drawer){
        window.analytics.track('[Menu][Open]', {}, {
        integrations: {
          Amplitude: {
            'session_id': this.ampid
          }
        }
      })
      }else{
        window.analytics.track('[Menu][Close]', {}, {
        integrations: {
          Amplitude: {
            'session_id': this.ampid
          }
        }
      })
      }
    },
    getpage(page){
        window.analytics.track('[Menu][Select_Page]['+page+']', {}, {
        integrations: {
          Amplitude: {
            'session_id': this.ampid
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
