<template>
  <app id="email_setting">
    <v-row>
      <v-col sm="2" md="auto" lg="auto">
        <h1 class="page-title">Email Setting</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Email List
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mx-3"
            ></v-text-field>

            <v-btn
              dark
              color="green"
              @click="(dialog_add_email = true), Open_Dialog_Add()"
            >
              <v-icon left>
                mdi-email-plus-outline
              </v-icon>
              Add
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="email_list"
            :search="search"
            :loading="loadTable"
          >
            <template v-slot:item.sent_email_status="{ item }">
              <v-btn
                class="no_pointer"
                :color="getColor(item.sent_email_status)"
                style="width: 82px;"
                small
                rounded
                dense
                dark
              >
                <div class="d-flex justify-center" style="text-align: center">
                  {{ item.sent_email_status }}
                </div>
              </v-btn>
            </template>

            <template v-slot:item.cc_email_status="{ item }">
              <v-btn
                class="no_pointer"
                :color="getColor(item.cc_email_status)"
                style="width: 82px;"
                small
                rounded
                dense
                dark
              >
                <div class="d-flex justify-center" style="text-align: center">
                  {{ item.cc_email_status }}
                </div>
              </v-btn>
            </template>

            <template v-slot:item.management="{ item }">
              <v-btn
                icon
                @click="(dialog_edit_email = true), getedit_email(item)"
                ><v-icon>mdi-email-edit-outline</v-icon></v-btn
              >

              <v-btn icon @click="(dialog_del_email = true), getdel_email(item)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>

            <v-progress-linear
              slot:progress
              color="blue"
              indeterminate
            ></v-progress-linear>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      :retain-focus="false"
      v-model="dialog_add_email"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Add E-mail</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-container>
          <v-card-text>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              outlined
              clearable
              required
            ></v-text-field>

            <div>
              <span class="font-weight-bold"> Sent E-mail </span
              ><v-switch v-model="switch_sent" color="green" inset></v-switch>
            </div>

            <div>
              <span class="font-weight-bold"> Cc E-mail </span>
              <v-switch v-model="switch_cc" color="green" inset></v-switch>
            </div>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              (dialog_add_email = false), clear(), Close_Dialog_Email_Add()
            "
          >
            Close
          </v-btn>
          <v-btn
            color="green"
            @click="(dialog_add_email = false), add_email(), clear()"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ************************************************************ -->

    <v-dialog
      :retain-focus="false"
      v-model="dialog_del_email"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Delete</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-container>
          <v-card-text>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              outlined
              clearable
              required
              disabled
            ></v-text-field>
            <div>
              <span class="font-weight-bold"> Sent E-mail </span
              ><v-switch v-model="switch_sent" color="green" inset disabled></v-switch>
            </div>

            <div>
              <span class="font-weight-bold"> Cc E-mail </span>
              <v-switch v-model="switch_cc" color="green" inset disabled></v-switch>
            </div>
          </v-card-text>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              (dialog_del_email = false), clear(), Close_Dialog_Delete_email()
            "
          >
            Close
          </v-btn>
          <v-btn
            color="#D9354B"
            @click="(dialog_del_email = false), del_email(), clear()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ************************************************************************ -->

    <v-dialog
      :retain-focus="false"
      v-model="dialog_edit_email"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-container>
          <v-card-text>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              outlined
              clearable
              required
            ></v-text-field>

            <div>
              <span class="font-weight-bold"> Sent E-mail </span
              ><v-switch v-model="switch_sent" color="green" inset></v-switch>
            </div>

            <div>
              <span class="font-weight-bold"> Cc E-mail </span>
              <v-switch v-model="switch_cc" color="green" inset></v-switch>
            </div>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              (dialog_edit_email = false), clear(), Close_Dialog_Edit_email()
            "
          >
            Close
          </v-btn>
          <v-btn
            color="#1888D8"
            @click="(dialog_edit_email = false), edit_email(), clear()"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </app>
</template>

<script>
//import {GridLayout, GridItem} from 'vue-grid-layout'
import jwtDecode from "jwt-decode";
import axios from "axios";

export default {
  name: "email_setting",
  components: {},
  data() {
    return {
      email_list: [],
      loadTable: false,
      search: "",
      dialog_add_email: false,
      email: "",
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      create_date: "",
      modified_date: "",
      sent_email_status: "",
      cc_email_status: "",
      switch_sent: false,
      switch_cc: false,
    };
  },

  created() {
    this.token = sessionStorage.getItem("user-token");
    this.decoded = jwtDecode(this.token);
    this._id = this.decoded._id;
    this.username = this.decoded.username;
    this.user_inst = this.decoded.instance;
    this.customer_name = this.decoded.customer_name;
    this.ampid = this.decoded.ampid;

    this.get_email();
  },

  computed: {
    headers() {
      return [
        { text: "Email", value: "email" },
        { text: "Create Date", value: "create_date", sortable: true },
        { text: "Modifiers Date", value: "modified_date" },
        { text: "Email Sent", value: "sent_email_status", align: "center",},
        { text: "Email Cc", value: "cc_email_status", align: "center",},
        {
          text: "Management",
          value: "management",
          align: "center",
        },
      ];
    },
  },

  methods: {
    async get_email() {
      this.email_list = [];
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Alert/Show_Email/",
          {
            customer_name: this.customer_name,
          }
        );
        //this.category = response.data;

        response.data.map((item) => {
          item.create_date = new Date(item.create_date).toLocaleString();
          item.modified_date = new Date(item.modified_date).toLocaleString();
          this.email_list.push(item);
        });
        this.loadTable = false;
      } catch (error) {
        alert("Incorrect email");
        console.error(error);
      }
    },
    async add_email() {
      if (this.switch_sent) {
        this.sent_email_status = "active";
      } else {
        this.sent_email_status = "inactive";
      }

      if (this.switch_cc) {
        this.cc_email_status = "active";
      } else {
        this.cc_email_status = "inactive";
      }

      let json_email = {
        email: this.email,
        create_date: Date.parse(new Date()),
        modified_date: Date.parse(new Date()),
        sent_email_status: this.sent_email_status,
        cc_email_status: this.cc_email_status,
      };
      console.log(json_email);
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Alert/Add_Email/",
          {
            customer_name: this.customer_name,
            data: json_email,
          }
        );

        console.log(response.data);
        window.analytics.track(
          "[Email_setting][Add][Email]",
          {
            email: this.email,
            sent_email_status: this.sent_email_status,
            cc_email_status: this.cc_email_status,
          },
          {
            integrations: {
              Amplitude: {
                session_id: this.ampid,
              },
            },
          }
        );
        this.get_email();
      } catch (error) {
        alert("Incorrect email");
        console.error(error);
      }
    },

    getdel_email(item) {

      if (item.sent_email_status == "active") {
        this.switch_sent = true;
      } else if (item.sent_email_status == "inactive"){
        this.switch_sent = false;
      }

      if (item.cc_email_status == "active") {
        this.switch_cc = true;
      } else if (item.cc_email_status == "inactive"){
        this.switch_cc = false;
      }

      this.id = item._id;
      this.email = item.email;

      window.analytics.track(
        "[Email_setting][Open_Dialog_Delete][Email]",
        {
          id: this.id,
          email: this.email,
        },
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    async del_email() {
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Alert/Delete_Email/",
          {
            customer_name: this.customer_name,
            id: this.id,
          }
        );
        let x = response.data;
        console.log(x);
        this.get_email();
        window.analytics.track(
          "[Email_setting][Delete][Email]",
          {
            id: this.id,
            email: this.email,
          },
          {
            integrations: {
              Amplitude: {
                session_id: this.ampid,
              },
            },
          }
        );
      } catch (error) {
        alert(error);
        console.error(error);
      }
    },

    getedit_email(item) {

      if (item.sent_email_status == "active") {
        this.switch_sent = true;
      } else if (item.sent_email_status == "inactive"){
        this.switch_sent = false;
      }

      if (item.cc_email_status == "active") {
        this.switch_cc = true;
      } else if (item.cc_email_status == "inactive"){
        this.switch_cc = false;
      }

      this.id = item._id;
      this.email = item.email;

      window.analytics.track(
        "[Email_setting][Open_Dialog_Edit][Email]",
        {
          id: this.id,
          email: this.email,
        },
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    async edit_email() {
      if (this.switch_sent) {
        this.sent_email_status = "active";
      } else {
        this.sent_email_status = "inactive";
      }

      if (this.switch_cc) {
        this.cc_email_status = "active";
      } else {
        this.cc_email_status = "inactive";
      }

      let json_email = {
        email: this.email,
        modified_date: Date.parse(new Date()),
        sent_email_status: this.sent_email_status,
        cc_email_status: this.cc_email_status,
      };
      console.log(json_email);

      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Alert/Edit_Email/",
          {
            customer_name: this.customer_name,
            id: this.id,
            data: json_email,
          }
        );
        let x = response.data;
        console.log(x);
        this.get_email();
        window.analytics.track(
          "[Email_setting][Open_Dialog_Edit][Email]",
          {
            customer_name: this.customer_name,
            id: this.id,
            data: json_email,
          },
          {
            integrations: {
              Amplitude: {
                session_id: this.ampid,
              },
            },
          }
        );
        //this.dialog_edit = false;
      } catch (error) {
        alert(error);
        console.error(error);
      }
    },

    Open_Dialog_Add(){
      window.analytics.track(
        "[Email_setting][Open_Dialog_Add][Email]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    Close_Dialog_Email_Add() {
      window.analytics.track(
        "[Email_setting][Close_Dialog_Add][Email]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    Close_Dialog_Delete_email() {
      window.analytics.track(
        "[Email_setting][Close_Dialog_Delete][Email]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    Close_Dialog_Edit_email() {
      window.analytics.track(
        "[Email_setting][Close_Dialog_Edit][Email]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    getColor(email_status) {
      if (email_status == "active") return "green";
      else if (email_status == "inactive") return "red";
    },

    clear() {
      this.email = "";
      this.sent_email_status = "";
      this.cc_email_status = "";
      this.switch_sent = false;
      this.switch_cc = false;
    },
  },
};
</script>

<style scoped>
.v-tab {
  text-transform: capitalize;
}

.no_pointer {
  cursor: initial;
}
</style>
