<template>

<app id="setting">
    
      <v-row>
        <v-col sm="" md="auto" lg="auto">
        <h1 class="page-title">Usecase Management</h1>
        </v-col> 
      </v-row>

   <v-row>
     <v-col cols="3" lg="3" sm="3">
       <v-card class="showItem-modal-body1" >
          <v-card-title class="pa-6 pb-3 ">
            <p style="font-size: 25px">Total Usecase</p>           
          </v-card-title >
            <v-card-text class="pa-6 pt-0 text-right ">
              <span style="font-size: 42px">{{ this.total }}</span>
            </v-card-text>
            </v-card>
      </v-col>
    
 
    
      <v-col cols="3" lg="3" sm="3" >
       <v-card class="showItem-modal-body2"> 
          <v-card-title class="pa-6 pb-3 ">
            <p style="font-size: 25px">Total High</p>           
          </v-card-title >
            <v-card-text class="pa-6 pt-0 text-right ">
              <span style="font-size: 42px">{{ this.high }}</span>
            </v-card-text>
            </v-card>
      </v-col>

      <v-col cols="3" lg="3" sm="3" >
        <v-card class="showItem-modal-body3">
          <v-card-title class="pa-6 pb-3">
            <p style="font-size: 25px">Totlal Medium</p>           
          </v-card-title >
            <v-card-text class="pa-6 pt-0 text-right">
              <span style="font-size: 42px">{{ this.medium }}</span>
            </v-card-text>
            </v-card>
      </v-col>

      <v-col cols="3" lg="3" sm="3" >
        <v-card class="showItem-modal-body4">
          <v-card-title class="pa-6 pb-3">
            <p style="font-size: 25px">Total Low</p>           
          </v-card-title >
            <v-card-text class="pa-6 pt-0 text-right">
              <span style="font-size: 42px">{{ this.low }}</span>
            </v-card-text>
        </v-card>
      </v-col>


  <v-row>
    <v-col>
        <v-col cols="auto" lg="5" sm="5" >
        <v-select
          :items="arrdevicetype"
          label="Device Type"
          v-model="device_type"
          dense
          rounded
          outlined
        ></v-select>
    </v-col>
    </v-col>

<v-col cols="auto" lg="5" sm="5" >
        <v-col >
            <v-text-field
             v-model="search"
               append-icon="mdi-magnify"
               label="Search here"
                single-line
                hide-details
                dense
                rounded
                outlined
             ></v-text-field>  
    </v-col>
    </v-col>
  </v-row>



<v-col cols="12">
              <v-data-table
            :headers="headers"
            :items="arrusecase"
            :loading="loadTable"
            :search="search"

          >

        <template v-slot:item.status="{ item }">
        <v-btn
          class="no_pointer"
          :color="getColor_status(item.status)"
          style = "width: 80px;" 
          small
          rounded
          dense      
          dark
        >
          {{ item.status }}
        </v-btn>
        </template>

        <template v-slot:item.level="{ item }">
        <v-btn
          class="no_pointer"
          :color="getColor_level(item.level)"
          style = "width: 80px;" 
          small
          rounded
          dense      
          dark
        >
          {{ item.level }}
        </v-btn>
        </template>

            <template v-slot:item.actions="{ item }">
              <v-btn icon @click="dialog_edit = true , getedit(item)"><v-icon>mdi-monitor-edit</v-icon></v-btn>
              <v-btn icon @click="dialog_detail = true , getdetail(item) "><v-icon>mdi-eye</v-icon></v-btn>


            </template>


          </v-data-table>



          </v-col>
   </v-row>

   <v-dialog
                :retain-focus="false"
                v-model="dialog_edit"
                scrollable
                max-width="400px"
    >
              <v-card>
                         <v-card-title>
          <span class="text-h5">Edit usecase detail</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-container>
          <v-card-text>

                      <div><span class="font-weight-bold"> Usecase Name : </span><span span class="font-weight-normal">{{ usecase }} </span></div>
                      <div><span class="font-weight-bold"> Device Type : </span><span span class="font-weight-normal">{{ device_type_edit }} </span></div>
                      <div><span class="font-weight-bold"> Severity Levle : </span><span span class="font-weight-normal">{{ level }} </span></div>
                      
                        <v-radio-group v-model="level">
                          <template v-slot:label>
                            <div><span class="font-weight-bold"> Severity Levle : </span></div>
                          </template>
                          <v-radio 
                            label="high"
                            color="#BC4D2A"
                            value="high"
                          ></v-radio>
                          <v-radio
                            label="medium"
                            color="#FFA000"
                            value="medium"
                          ></v-radio>
                          <v-radio
                            label="low"
                            color="#FDD835"
                            value="low"
                          ></v-radio>
                          
                        </v-radio-group>
                <div>
                  <span class="font-weight-bold"> Status </span
                  ><v-switch v-model="switch_status_usecase" color="green" inset></v-switch>
                </div>
                  
                </v-card-text>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog_edit = false, clear(),Close_Dialog_Edit()">
                    Close
                  </v-btn>
                  <v-btn color="blue darken-1" dark @click="dialog_edit = false,edit_usecase(), clear()">
                    Edit
                  </v-btn>
                </v-card-actions>
              </v-card>
    </v-dialog>


<v-dialog
                :retain-focus="false"
                v-model="dialog_detail"
                scrollable
                max-width="400px"
    >
              <v-card>
         <v-card-title>
          <span class="text-h5">Usecase detail</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-container>
          <v-card-text>


                      <div><span class="font-weight-bold"> Usecase Name : </span><span span class="font-weight-normal">{{ usecase }} </span></div>
                      <div><span class="font-weight-bold"> Device Type : </span><span span class="font-weight-normal">{{ device_type_edit }} </span></div>
                      <div><span class="font-weight-bold"> Severity Levle : </span><span span class="font-weight-normal">{{ level }} </span></div>
                      <br>
                      <v-btn icon @click="dialog_edit = true , dialog_detail = false"><v-icon>mdi-monitor-edit</v-icon></v-btn>


                      </v-card-text>
                  </v-container>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog_detail = false , clear() ,Close_Dialog_Detail()">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
    </v-dialog>
      
</app>

</template>

<script>
import jwtDecode from "jwt-decode";
import axios from "axios";




export default {
    name:'setting',
    components: {
    },
  data () {
    return {
    arrusecase: [],
    arrdevicetype: [],
    device_type: "all",
    usecase: "",
    device_name: "",
    level: "",
    total: "",
    high: "",
    medium: "",
    low: "",
    dialog_edit: false,
    dialog_detail: false,
    loadTable: false,
    search:"",
    device_type_edit:"",
    status:"",
    switch_status_usecase: false,
    }
  },
  created () {
    this.token = sessionStorage.getItem("user-token");
    this.decoded = jwtDecode(this.token);
    this.ampid = this.decoded.ampid

    this.customer_name = this.decoded.customer_name;
    this.customer_details = "UsecaseParameter";
    this._id = this.decoded._id;


    this.getusecase();
    this.gettotal();
    
  },
  computed: {
   headers() {
      return [
        { text: "Usecase", value: "usecase" },
        { text: "Device Name", value: "device_name" },
        { text: "Device Type", value: "device_type", 
        filter: (value) => {
            if (!this.device_type) return true;
            if (value == this.device_type) return value;
            if (this.device_type == "all") {
              return true
            }
          },
          },
        { text: "Status", value: "status", align: "center"},
        { text: "Levle", value: "level", align: "center"},
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ];
    },  
  },
  methods: {
  async getusecase() {
    this.loadTable = true;
    this.arrusecase = [];
    try {
      let response = await axios.post("https://backstage.v-insight.co:8001" + "/api/Setting/Show_UsecaseParameter/",
      {
        customer_name: this.customer_name,
        customer_details: this.customer_details,
      }
      );
      response.data.map((item) => {
        this.arrusecase.push(item);
      });
      response.data.map((item) => {
        this.arrdevicetype[0] = "all";
        this.arrdevicetype.push(item.device_type);
      });
      this.loadTable = false;
    } catch (error) {
      alert('xxx usecase');
      console.error(error);
    }
  },

 async gettotal() {
    try {
      let response = await axios.post("https://backstage.v-insight.co:8001" + "/api/Setting/Usecase_Management/Count_total_Level/",
      {
        customer_name: this.customer_name,
      }
      );
      this.total = response.data.total;
      this.high = response.data.high;
      this.medium = response.data.medium;
      this.low = response.data.low;


    } catch (error) {
      alert('xxx total');
      console.error(error);
    }
  },
    getdetail(item) {
      this.id = item._id;
      this.usecase = item.usecase;
      this.device_name = item.device_name;
      this.device_type_edit = item.device_type;
      this.level = item.level;
      this.status = item.status;
     
      // console.log(this.usecase);
      // console.log(this.device_name);
      // console.log(this.device_type);
      // console.log(this.level);
      window.analytics.track(
        "[Setting][Open_Dialog_Detail][Level]",
        {
          id : item._id,
          usecase : item.usecase,
          device_name : item.device_name,
          device_type : item.device_type,
          level : item.level,
          status : item.status
        },
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    
    },
          Close_Dialog_Detail(){
      window.analytics.track(
        "[Users][Close_Dialog_Detail][Level]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    getedit(item) {
      if (item.status == "active") {
        this.switch_status_usecase = true;
      } else if (item.status == "inactive"){
        this.switch_status_usecase = false;
      }

      this.id = item._id;
      this.usecase = item.usecase;
      this.device_name = item.device_name;
      this.device_type_edit = item.device_type;
      this.status = item.status;
      this.level = item.level;
     
      // console.log(this.usecase);
      // console.log(this.device_name);
      // console.log(this.device_type);
      // console.log(this.level);
      window.analytics.track(
        "[Setting][Open_Dialog_Edit][Level]",
        {
          id : item._id,
          usecase : item.usecase,
          device_name : item.device_name,
          device_type : item.device_type,
          level : item.level
        },
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    
    },
    

  async edit_usecase () {
      if (this.switch_status_usecase) {
        this.status = "enable";
      } else {
        this.status = "disable";
      }

      let json = {
        level: this.level,
        status: this.status
        // description: this.description,
      };
      // console.log(json);
      // console.log(this.id)
      
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Setting/Edit_UsecaseParameter/",
          {           
            customer_name: this.customer_name,
            id: this.id,
            data: json,
          }
          );
        let x = response.data;
        console.log(x);
        this.getusecase();
        this.gettotal();
        console.log(json);
        window.analytics.track(
        "[Setting][Edit][Level]",
        {
            customer_name: this.customer_name,
            id: this.id,
            data: json,
        },
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
      }catch (error) {
        alert("Incorrect editlevel");
        console.error(error);
      }
  },

          Close_Dialog_Edit(){
      window.analytics.track(
        "[Users][Close_Dialog_Edit][Level]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    getColor_level (level) {
      if (level  == 'high') return '#BC4D2A'
      else if (level == 'medium') return '#FFA000'
      else return '#FDD835'
    },

    getColor_status (status) {
      if (status  == 'enable') return 'green'
      else if (status == 'disable') return 'red'
    },

    clear() {
      this.id = '';
      this.level = ''
      this.status = ''
      this.switch_status_usecase = false; 
    }

  },
}
</script>

<style scoped>
.showItem-modal-body1 {
  border-left: 10px solid white !important;
}
.showItem-modal-body2 {
  border-left: 10px solid #BC4D2A !important;
}
.showItem-modal-body3 {
  border-left: 10px solid #FFA000 !important;
}
.showItem-modal-body4 {
  border-left: 10px solid #FDD835 !important;
}

.no_pointer {
  cursor: initial;
}

</style>