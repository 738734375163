<template>
  <app id="add_asset">
    <v-row>
      <v-col sm="2" md="auto" lg="auto">
        <h1 class="page-title">Add Users</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="col-auto">
        <v-card>
          <v-card-title>
            Users list
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mx-3"
            >
            </v-text-field>

            <v-btn
              dark
              color="green"
              class="mx-2"
              @click="dialog = true, Open_Dialog_Add()"
            >
              <v-icon left>
                mdi-account-multiple-plus-outline
              </v-icon>
              Add
            </v-btn>

            <v-btn
              dark
              color="green"
              @click="dialog_upload = true, Open_Dialog_Upload()"
            >
              <v-icon left>
                mdi-account-multiple-plus-outline
              </v-icon>
              Upload CSV
            </v-btn>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="datatables"
            :search="search"
            :loading="loadTable"
          >

             <template v-slot:item.status="{ item }">
              <v-btn
                class="no_pointer"
                :color="getColor(item.status)"
                style="width: 82px;"
                small
                rounded
                dense
                dark
              >
                <div class="d-flex justify-center" style="text-align: center">
                  {{ item.status }}
                </div>
              </v-btn>
            </template>

            <template v-slot:item.privilege="{ item }">
              <v-btn
                class="no_pointer"
                :color="getColor(item.privilege)"
                style="width: 80px;"
                small
                rounded
                dense
                dark
              >
                {{ item.privilege }}
              </v-btn>
            </template>

            <template v-slot:item.management="{ item }">
              <v-btn icon @click="(dialog_edit = true), getedituser(item)"
                ><v-icon>mdi-account-edit</v-icon></v-btn
              >

              <v-btn icon @click="(dialog_del = true), getdeluser(item)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>

            <v-progress-linear
              slot:progress
              color="blue"
              indeterminate
            ></v-progress-linear>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add user</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-container>
          <v-card-text>
            <v-text-field
              v-model="user"
              label="User Names"
              outlined
              clearable
              required
            ></v-text-field>

            <v-select
              :items="arrasset"
              label="Asset"
              v-model="user_in_asset"
              outlined
            ></v-select>

            <v-select
              :items="arrstatus"
              label="Status"
              v-model="status"
              outlined
            ></v-select>

            <v-radio-group v-model="privilege" row>
              <template v-slot:label>
                <div><strong>privilege : </strong></div>
              </template>
              <v-radio label="high" color="#002E60" value="high"></v-radio>
              <v-radio label="medium" color="#2B2B2B" value="medium"></v-radio>

              <v-radio label="low" color="#878B92" value="low"></v-radio>
            </v-radio-group>

            <v-text-field
              v-model="description"
              label="Description"
              outlined
              clearable
              required
            ></v-text-field>
          </v-card-text>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="(dialog = false), clear(), Close_Dialog_Add()"
          >
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            @click="(dialog = false), adduser(), clear()"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- **************************************************************** -->

    <v-dialog
      :retain-focus="false"
      v-model="dialog_del"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Delete</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-container>
          <v-card-text>
            <v-text-field
              v-model="user"
              label="User Names"
              outlined
              clearable
              required
              disabled
            ></v-text-field>

            <v-select
              :items="arrasset"
              label="Asset"
              v-model="user_in_asset"
              outlined
              disabled
            ></v-select>

            <v-select
              :items="arrstatus"
              label="Status"
              v-model="status"
              outlined
              disabled
            ></v-select>

            <v-radio-group v-model="privilege" disabled row>
              <template v-slot:label>
                <div><strong>privilege : </strong></div>
              </template>
              <v-radio label="high" color="#002E60" value="high"></v-radio>
              <v-radio label="medium" color="#2B2B2B" value="medium"></v-radio>

              <v-radio label="low" color="#878B92" value="low"></v-radio>
            </v-radio-group>

            <v-text-field
              v-model="description"
              label="Description"
              outlined
              clearable
              required
              disabled
            ></v-text-field>
          </v-card-text>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="(dialog_del = false), clear(), Close_Dialog_Delete()"
          >
            Close
          </v-btn>
          <v-btn
            color="#D9354B"
            @click="(dialog_del = false), deluser(), clear()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ************************************************************************** -->

    <v-dialog
      :retain-focus="false"
      v-model="dialog_edit"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-container>
          <v-card-text>
            <v-text-field
              v-model="user"
              label="User Names"
              outlined
              clearable
              required
            ></v-text-field>

            <v-select
              :items="arrasset"
              label="Asset"
              v-model="user_in_asset"
              outlined
            ></v-select>

            <v-select
              :items="arrstatus"
              label="Status"
              v-model="status"
              outlined
            ></v-select>

            <v-radio-group v-model="privilege" row>
              <template v-slot:label>
                <div><strong>privilege : </strong></div>
              </template>
              <v-radio label="high" color="#002E60" value="high"></v-radio>
              <v-radio label="medium" color="#2B2B2B" value="medium"></v-radio>

              <v-radio label="low" color="#878B92" value="low"></v-radio>
            </v-radio-group>

            <v-text-field
              v-model="description"
              label="Description"
              outlined
              clearable
              required
            ></v-text-field>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="(dialog_edit = false), clear(), Close_Dialog_Edit()"
          >
            Close
          </v-btn>
          <v-btn
            color="#1888D8"
            @click="(dialog_edit = false), edituser(), clear()"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
          :retain-focus="false"
          v-model="dialog_upload"
          scrollable
          max-width="700px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Upload</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-container>
              <v-card-text>
                <v-file-input
                  accept="text/csv"
                  label="Click here to select a .csv file"
                  outlined
                  required
                  v-model="csvfile"
                  @change="onFilePicked()"
                >
                </v-file-input>

                <div class="font-weight-medium">
                  <span style="color : red" class="font-weight-bold">* </span
                  >ตัวอย่างรูปแบบเอกสาร รับเฉพาะ
                  <span style="color : red" class="font-weight-bold">
                    CSV File</span
                  >
                </div>

                <img
                  :src="CSV_EX"
                  width="100%"
                  height="auto"
                  class="responsive pr-2"
                />

                <v-alert dense outlined type="error">
                  Username เฉพาะผู้มีสิทธิ์ในการเข้าถึง Hostname เท่านั้น
                </v-alert>
              </v-card-text>
            </v-container>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="(dialog_upload = false), Close_Dialog_Upload()"
              >
                Close
              </v-btn>
              <v-btn color="blue" dark @click="upload()">
                Upload
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </app>
</template>

<script>
import jwtDecode from "jwt-decode";

import axios from "axios";

export default {
  name: "add_asset",
  components: {},
  data() {
    return {
      user: "",
      privilege: "",
      description: "",
      device_name: "",
      status: "",
      user_in_asset: "",
      datatables: [],
      dialog: false,
      dialog_edit: false,
      dialog_del: false,
      search: "",
      arrasset: [],
      arrstatus: ["active", "inactive"],
      // select_status: { state: this.status},
      // select_asset: { state: this.user_in_asset},
      // ip_edit: "",
      // hostname_edit: "",
      // severity_edit: "",
      // description_edit: "",
      // open_port_edit: "",

      dialog_upload: false,
      csvfile: "",
      userNameListfillter: [],

      arrheaders: ["user", "device_name", "privilege", "description", "status"],
      csv_check_head: false,
      csv_headers: [],
      json: [],
      csv_check_value: false,
    };
  },
  created() {
    this.CSV_EX = require("../assets/img/CSV_EX.png");
    this.token = sessionStorage.getItem("user-token");
    this.decoded = jwtDecode(this.token);
    this.ampid = this.decoded.ampid;
    this._id = this.decoded._id;
    this.username = this.decoded.username;
    this.customer_name = this.decoded.customer_name;
    this.getusers();
    this.getasset();
  },
  computed: {
    headers() {
      return [
        // { text: "ID", value: "_id" },
        { text: "User", value: "user" },
        { text: "Device name", value: "device_name" },
        { text: "Privilege", value: "privilege" , align: "center"},
        // { text: "Device name", value: "dev_name" },
        {
          text: "Status",
          value: "status",
          align: "center"
        },
        {
          text: "Management",
          value: "management",
          align: "center"
        },
      ];
    },
  },
  methods: {
    async getasset() {
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Add_Asset/Show_Asset/",
          {
            customer_name: this.customer_name,
          }
        );
        response.data.map((item) => {
          this.arrasset.push(item.hostname);
        });
      } catch (error) {
        alert("Incorrect email");
        console.error(error);
      }
    },

    async getusers() {
      this.loadTable = true;
      this.datatables = [];
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Add_User/Show_Userlist/",
          {
            customer_name: this.customer_name,
          }
        );
        response.data.map((item) => {
          this.datatables.push(item);
        });
        console.log(this.datatables)
        this.loadTable = false;
      } catch (error) {
        alert("Incorrect email");
        console.error(error);
      }
    },

    Open_Dialog_Add() {
      window.analytics.track(
        "[Add_users][Open_Dialog_Add][User]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },
    Close_Dialog_Add() {
      window.analytics.track(
        "[Add_users][Close_Dialog_Add][User]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

      csvToArray(str, delimiter = ",") {
      // slice from start of text to the first \n index
      // use split to create an array from string by delimiter
      const headers = str.slice(0, str.indexOf("\r\n")).split(delimiter);
      this.csv_headers = headers;
      // slice from \n index + 1 to the end of the text
      // use split to create an array of each csv value row
      const rows = str
        .slice(str.indexOf("\n") + 1)
        .split("\r\n")
        .filter((data) => data !== "");

      // while (i < this.arrheaders.length) {
      //   if(this.arrheaders[i]==this.csv_headers[j]){
      //         this.csc_check_head = true
      //     }else{
      //         this.csc_check_head = false
      //         j++
      //     }
      //   i++;
      // }
      // for (let i = 0; i < this.arrheaders.length; i++) {
      //   for (let j = 0; j < this.csv_headers.length; j++) {
      //     if(this.arrheaders[i]==this.csv_headers[j]){
      //       this.csc_check_head = true
      //       j = 0
      //       break;
      //     }else{
      //       this.csc_check_head = false
      //       // alert(this.csv_headers[j] + "ไม่ถูกต้อง")
      //   }
      // }
      // }
      // console.log(this.csc_check_head)

      // this.csc_check_head = true;
      const arr = rows.map(function(row) {
        const values = row.split(delimiter);
        const el = headers.reduce(function(object, header, index) {
          object[header] = values[index];
          return object;
        }, {});
        return el;
      });

      // return the array
      return arr;
      // Map the rows
      // split values from each row into an array
      // use headers.reduce to create an object
      // object properties derived from headers:values
      // the object passed as an element of the array
    },
    // check() {
    //           const result = JSON.stringify(this.csv_headers) === JSON.stringify(this.arrheaders);

    //       // if result is true
    //       if (result) {
    //         this.csv_check_head = true
    //       }else{
    //         this.csv_check_head = false
    //       }
    // },

    onFilePicked() {
      window.analytics.track(
        "[Users][FilePicked]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
      var csvfile = this.csvfile;     
      console.log("csvfile.type" , csvfile.type )

      if (csvfile != undefined) {
        if (csvfile.type == "application/vnd.ms-excel") {
          const reader = new FileReader();
          // const input = csvfile.files[0];
          reader.onload = async (e) => {
            const text = e.target.result;
            const data = this.csvToArray(text);
            // document.write(JSON.stringify(data));
            this.json = data;
            // console.log(this.json);
            // console.log(this.clean(data));
          };

          reader.readAsText(this.csvfile);
        } else{
          // this.csvfile = '';
          alert("กรุณาเลือกไฟล์ .csv");
          this.csvfile = ""
          // console.error(error);
        }
      }else{
          alert("กรุณาเลือกไฟล์");
          // this.csvfile = ""
          window.analytics.track(
            "[Users][Upload_Fail][Header_CSV_Notmatch]",
            {},
            {
              integrations: {
                Amplitude: {
                  session_id: this.ampid,
                },
              },
            }
          );
        }
    },

    async upload() {

      var csvfile = this.csvfile;
      var newjson = [];

      //debugger
      // console.log(csvfile);

     if (csvfile != undefined) {
        if (csvfile.type == "application/vnd.ms-excel") {
          if (this.csv_headers.sort().join(",") === this.arrheaders.sort().join(",")) {
          this.json.map((item) => {
            // if (
            //   item.user != "" &&
            //   item.device_name != "" &&
            //   item.privilege != "" &&
            //   item.status != ""
            // ) {
            if (
              item.user != "" &&
              item.device_name != "" &&
              item.privilege != "" &&
              item.status != "" &&
              (item.privilege == "low" ||
                item.privilege == "medium" ||
                item.privilege == "high") &&
              (item.status == "inactive" || item.status == "active")
            ) {
              //   item.privilege = "low"
              //   item.status = "active"
              newjson.push(item);
              this.csv_check_value = true;
            } else {
              this.csv_check_value = false;
            }
            // }
          });
          // console.log("++++++++++++++++");
          // console.log(newjson);
          if (this.csv_check_value) {
            try {
              let response = await axios.post(
                "https://backstage.v-insight.co:8001" +
                  "/api/Add/Upload_Userlist/",
                {
                  customer_name: this.customer_name,
                  data: newjson,
                }
              );
              console.log(response);
              alert("Upload successful");
              this.dialog_upload = false;
              this.getusers();
              this.csvfile = ""
              // alert("Upload successful");
              window.analytics.track(
                "[Users][Upload_success]",
                {},
                {
                  integrations: {
                    Amplitude: {
                      session_id: this.ampid,
                    },
                  },
                }
              );
            } catch (error) {
              console.error(error);
            }
            // alert("ได้");
          } else {
            alert("value ไม่ถูกต้อง กรุณาตรวจสอบ ไฟล์ .CSV");
            this.csvfile = ""
            window.analytics.track(
              "[Users][Upload_Fail][Value_CSV_Notmatch]",
              {},
              {
                integrations: {
                  Amplitude: {
                    session_id: this.ampid,
                  },
                },
              }
            );
          }
        } else {
          alert("header ไม่ถูกต้อง กรุณาตรวจสอบ ไฟล์ .CSV");
          this.csvfile = ""
          window.analytics.track(
            "[Users][Upload_Fail][Header_CSV_Notmatch]",
            {},
            {
              integrations: {
                Amplitude: {
                  session_id: this.ampid,
                },
              },
            }
          );
        }
        } else {
          alert("กรุณาเลือกไฟล์ .csv");
          this.csvfile = ""
          window.analytics.track(
            "[Users][Upload_Fail][Header_CSV_Notmatch]",
            {},
            {
              integrations: {
                Amplitude: {
                  session_id: this.ampid,
                },
              },
            }
          );
        }
        // const result =
        //   JSON.stringify(this.csv_headers) === JSON.stringify(this.arrheaders);
        // for (let i = 0; i < this.csv_headers.length; i++) {
        //   this.csv_headers.forEach(function(csv_headers){
        //       this.comparator(csv_headers)
        //   })
        // // }
        // console.log("=======================");
        // console.log(this.csv_check_head);
        // if result is true
      } else {
        alert("กรุณาเลือกไฟล์");
        window.analytics.track(
          "[Users][Upload_Fail][Not_FilePicked]",
          {},
          {
            integrations: {
              Amplitude: {
                session_id: this.ampid,
              },
            },
          }
        );
      }
    },

    Open_Dialog_Upload() {
      window.analytics.track(
        "[Users][Open_Dialog_Upload]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    Close_Dialog_Upload() {
      window.analytics.track(
        "[Users][Close_Dialog_Upload]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    async adduser() {
      // this.user = ''
      // this.privilege = ''
      // this.description = ''
      // this.device_name = ''
      let json = {
        user: this.user,
        device_name: this.user_in_asset,
        privilege: this.privilege,
        status: this.status,
        description: this.description,
      };
      console.log(json);

      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Add_User/Add_User/",
          {
            customer_name: this.customer_name,
            data: json,
          }
        );
        let x = response.data;
        console.log(x);
        this.getusers();
        window.analytics.track(
          "[Add_users][Add][User]",
          {},
          {
            integrations: {
              Amplitude: {
                session_id: this.ampid,
              },
            },
          }
        );
        // this.dialog = false;
      } catch (error) {
        alert("Incorrect email");
        console.error(error);
      }
    },

    getdeluser(item) {
      this.id = item._id;
      this.user = item.user;
      this.user_in_asset = item.device_name;
      this.privilege = item.privilege;
      this.status = item.status;
      this.description = item.description;

      console.log(this.id);
      console.log(this.ip);
      console.log(this.hostname);
      console.log(this.open_port);
      console.log(this.severity);
      window.analytics.track(
        "[Add_users][Open_Dialog_Delete][User]",
        {
          id: item._id,
          user: item.user,
          user_in_asset: item.device_name,
          privilege: item.privilege,
          status: item.status,
          description: item.description,
        },
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    async deluser() {
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Add_User/Delete_User/",
          {
            customer_name: this.customer_name,
            id: this.id,
          }
        );
        let x = response.data;
        console.log(x);
        this.getusers();
        window.analytics.track(
          "[Add_users][Delete][User]",
          {
            customer_name: this.customer_name,
            id: this.id,
          },
          {
            integrations: {
              Amplitude: {
                session_id: this.ampid,
              },
            },
          }
        );
      } catch (error) {
        console.error(error);
      }
    },

    getedituser(item) {
      this.id = item._id;
      this.user = item.user;
      this.user_in_asset = item.device_name;
      this.privilege = item.privilege;
      this.status = item.status;
      this.description = item.description;
      console.log(this.id);
      console.log(this.user);
      console.log(this.user_in_asset);
      console.log(this.privilege);
      console.log(this.status);
      window.analytics.track(
        "[Add_users][Open_Dialog_Edit][User]",
        {
          id: item._id,
          user: item.user,
          user_in_asset: item.device_name,
          privilege: item.privilege,
          status: item.status,
          description: item.description,
        },
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    async edituser() {
      // let ip = this.ip;
      // let hostname = this.hostname;
      // let severity = this.severity;
      // let description = this.description;

      // let str_port = this.open_port;
      // console.log(str_port);
      // var arrport = str_port.split(","); // split string on comma space
      // console.log(arrport);
      let json = {
        user: this.user,
        device_name: this.user_in_asset,
        privilege: this.privilege,
        status: this.status,
        description: this.description,
      };
      console.log(json);

      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Add_User/Edit_User/",
          {
            customer_name: this.customer_name,
            id: this.id,
            data: json,
          }
        );
        let x = response.data;
        console.log(x);
        this.getusers();
        window.analytics.track(
          "[Add_users][Open_Dialog_Edit][User]",
          {
            customer_name: this.customer_name,
            id: this.id,
            data: json,
          },
          {
            integrations: {
              Amplitude: {
                session_id: this.ampid,
              },
            },
          }
        );
        //this.dialog_edit = false;
      } catch (error) {
        alert("Incorrect email");
        console.error(error);
      }
    },

    Close_Dialog_Delete() {
      window.analytics.track(
        "[Add_users][Close_Dialog_Delete][User]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    Close_Dialog_Edit() {
      window.analytics.track(
        "[Add_users][Close_Dialog_Edit][User]",
        {},
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
    },

    getColor(value) {
      if (value == "high") return "#002E60";
      else if (value == "medium") return "#2B2B2B";
      else if (value == "low") return "#878B92";
      else if (value == "active") return "green";
      else if (value == "inactive") return "#BC4D2A";
    },

    clear() {
      this.id = "";
      this.user = "";
      this.user_in_asset = "";
      this.privilege = "";
      this.status = "";
      this.description = "";
    },
  }
}
</script>
<style>
.no_pointer {
  cursor: initial;
}
</style>
