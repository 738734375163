<template>
  <app id="users">
    <v-row>
      <v-col sm="auto" md="auto" lg="auto">
        <h1 class="page-title">User</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="4" sm="4" md="3" lg="auto">
        <v-select
          :items="arrstatus"
          label="Status"
          v-model="status"
          @click="select_status()"
          dense
          rounded
          outlined
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="col-auto">
        <v-card>
          <v-card-title>
            User
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mx-3"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="users"
            :search="search"
            :loading="loadTable"
          >
            <template v-slot:item.status="{ item }">
              <v-btn
                class="no_pointer"
                :color="getColor(item.status)"
                style="width: 82px;"
                small
                rounded
                dense
                dark
              >
                <div class="d-flex justify-center" style="text-align: center">
                  {{ item.status }}
                </div>
              </v-btn>
            </template>

            <template v-slot:item.detail="{ item }">
              <v-icon small class="mr-2" @click="linkItem(item)">
                mdi-eye
              </v-icon>
            </template>

            <v-progress-linear
              slot:progress
              color="blue"
              indeterminate
            ></v-progress-linear>
          </v-data-table>
        </v-card>

        <v-dialog
          :retain-focus="false"
          v-model="dialog_upload"
          scrollable
          max-width="700px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Upload</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-container>
              <v-card-text>
                <v-file-input
                  accept="text/csv"
                  label="Click here to select a .csv file"
                  outlined
                  required
                  v-model="csvfile"
                  @change="onFilePicked"
                >
                </v-file-input>

                <div class="font-weight-medium">
                  <span style="color : red" class="font-weight-bold">* </span
                  >ตัวอย่างรูปแบบเอกสาร รับเฉพาะ
                  <span style="color : red" class="font-weight-bold">
                    CSV File</span
                  >
                </div>

                <img
                  :src="CSV_EX"
                  width="100%"
                  height="auto"
                  class="responsive pr-2"
                />

                <v-alert dense outlined type="error">
                  Username เฉพาะผู้มีสิทธิ์ในการเข้าถึง Hostname เท่านั้น
                </v-alert>
              </v-card-text>
            </v-container>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="(dialog_upload = false), Close_Dialog_Upload()"
              >
                Close
              </v-btn>
              <v-btn color="blue" dark @click="upload()">
                Upload
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </app>
</template>

<script>
import jwtDecode from "jwt-decode";

import axios from "axios";

// import S3 from 'aws-s3';

export default {
  name: "users",
  components: {},
  created() {
    // this.CSV_EX = require("../assets/img/CSV_EX.png");

    this.token = sessionStorage.getItem("user-token");
    this.decoded = jwtDecode(this.token);
    this.ampid = this.decoded.ampid;
    this._id = this.decoded._id;

    this.customer_name = this.decoded.customer_name;
    this.getusers();
  },
  data() {
    return {
      status: "all",
      loadTable: false,
      users: [],
      arrstatus: [],
      search: "",
      // dialog_upload: "",
      // csvfile: "",
      // userNameListfillter: [],

      // arrheaders: ["user", "device_name", "privilege", "description", "status"],
      // csv_check_head: false,
      // csv_headers: [],
      // json: [],
      // csv_check_value: false,
    };
  },
  computed: {
    headers() {
      return [
        { text: "Usernames", value: "user" },
        {
          text: "Status",
          value: "status",
          filter: (value) => {
            if (!this.status) return true;
            if (value == this.status) return value;
            if (this.status == "all") {
              return true;
            }
          },
        },
        {
          text: "Detail",
          value: "detail",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    async getusers() {
      this.users = [];
      this.loadTable = true;
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Add_User/Show_Userlist/",
          {
            customer_name: this.customer_name,
          }
        );
        response.data.map((item) => {
          this.users.push(item);
        });
        response.data.map((item) => {
          this.arrstatus[0] = "all";
          // this.arrstatus.push(item.status);
          if (item.status == "inactive" || item.status == "active") {
            this.arrstatus.push(item.status);
          }
        });
        console.log(this.user);
        this.loadTable = false;
      } catch (error) {
        alert("Incorrect email");
        console.error(error);
      }
    },

    getColor(value) {
      if (value == "active") return "green";
      else if (value == "inactive") return "#BC4D2A";
    },

    linkItem(item) {
      window.analytics.track(
        "[Users][Drilldown_Users][" + item.user + "]",
        {
          user: item.user,
        },
        {
          integrations: {
            Amplitude: {
              session_id: this.ampid,
            },
          },
        }
      );
      this.$router.push({
        name: "user_analytic",
        params: {
          user: item.user,
        },
      });
    },
  },
};
</script>
