<template>
  <app id="alert">
    <v-row>
      <v-col sm="auto" md="auto" lg="auto">
        <h1 class="page-title">Alert</h1>
      </v-col>
    </v-row>

    <!-- ******************************** -->
    <v-row>
      <v-col sm="2" md="auto" lg="auto">
        <VueCtkDateTimePicker
          v-model="start"
          format="YYYY-MM-DD HH:mm"
          label="Select Time"
          :minute-interval="1"
          class="field-input[data-v-5b500588]"
          dark
        >
        </VueCtkDateTimePicker>
      </v-col>

      <v-col sm="2" md="auto" lg="auto">
        <h2>To</h2>
      </v-col>
      <v-col sm="2" md="auto" lg="auto">
        <VueCtkDateTimePicker
          v-model="end"
          format="YYYY-MM-DD HH:mm"
          label="Select Time"
          :minute-interval="1"
          dark
        >
        </VueCtkDateTimePicker>
      </v-col>
      <v-col sm="2" md="auto" lg="auto">
        <v-btn rounded color="#32CD32" class="mx-1" dark @click="dateFillter">
          SUBMIT
        </v-btn>

        <v-btn
          rounded
          color="#121212"
          class="mx-1"
          style="border: 1px solid #C7C7C7;"
          dark
          @click="clearFillter"
        >
          <v-icon>mdi-notification-clear-all</v-icon><div style="font-weight: lighter;">CLEAR</div>
        </v-btn>
      </v-col>

    </v-row>
    <!-- *******************!-->


    <v-row>
      <v-col auto>
        <v-card>
          <v-tabs
            v-model="tab_Level"
            grow
            show-arrows
            :color="getColor(this.arrLevel[this.tab_Level])"
          >
            <v-tabs-slider
              :color="getColor(this.arrLevel[this.tab_Level])"
            ></v-tabs-slider>

            <v-tab
              v-for="item in arrLevel"
              :key="item"
              @click.native="getLevel()"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <!--                 -->
    <v-row>
      <v-col cols="col-auto">
        <v-card>
          <v-card-title>
            Alert list
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>

          </v-card-title>


          <v-data-table
            :headers="headers"
            :items="datatables"
            :search="search"
            :loading="loadTable"
            :sort-compare="myCompare"
          >
            <template v-slot:item.level="{ item }">
              <v-btn
                class="no_pointer"
                :color="getColor(item.level)"
                style="width: 80px;"
                small
                rounded
                dense
                dark
              >
                <div class="d-flex justify-center" style="text-align: center">
                  {{ item.level }}
                </div>
              </v-btn>
            </template>

            <template v-slot:item.ilog="{ item }">
              
                <v-img
                  class="mx-auto my-auto pointer"
                  :src="logo_ilog"
                  @click="linkilog(item)"
                  
                  width="18px"
                  height="auto">
                </v-img>
              
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="linkItem(item)">
                mdi-eye
              </v-icon>
            </template>

            <template v-slot:item.management="{ item }">
              <v-btn
                :color="getbtn_Color(item.stage)"
                style="width: 135px;"
                @click="(dialog = true), getItem(item)"
                >{{ item.stage }}</v-btn
              >
            </template>
          </v-data-table>
        </v-card>

        <v-dialog
          :retain-focus="false"
          v-model="dialog"
          scrollable
          max-width="325px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Management</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-container>
              <v-card-text>
                <div>Datetime: {{ this.time }}</div>
                <div>Use Case: {{ this.usecase }}</div>
                <div>Device: {{ this.device_vendor }}</div>
                <div>Hostname: {{ this.src_ip }}</div>

                <div>Level: {{ this.level }}</div>

                <v-radio-group v-model="stage" column>
                  <template v-slot:label>
                    <div><strong>Stage : </strong></div>
                  </template>
                  <v-radio label="Open" value="open" color="red"></v-radio>
                  <v-radio
                    label="In progress"
                    value="in progress"
                    color="blue"
                  ></v-radio>
                  <v-radio label="Close" value="close" color="green"></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-container>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn
                color="green darken-1"
                @click="(dialog = false), editStage()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </app>
</template>

<script>
import jwtDecode from "jwt-decode";

import axios from "axios";

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
  name: "alert",
  components: {
    VueCtkDateTimePicker
  },
  data() {
    return {
      showFillterData: false,
      datatables: [],
      loadTable: false,
      start: "",
      end: "",
      // startdate: Date.parse(new Date()) - 2592000000,
      // enddate: Date.parse(new Date()),
      startdate: Date.parse(new Date()) - 86400000,
      enddate: Date.parse(new Date()),
      // result: [],
      // headers: [],
      item: [],
      dialog: false,
      dialog_email: false,
      select_stage: "",
      device_vendor: "",
      id: "",
      search: "",
      arrLevel: ["all", "high", "medium", "low"],
      tab_Level: 0,
      select_level: "all",
      stage: "",
      src_ip: "",
      usecase: "",

    };
  },


  created() {
    this.logo_ilog = require("../assets/img/favicon.png");
    this.token = sessionStorage.getItem("user-token");
    this.decoded = jwtDecode(this.token);
    this.ampid = this.decoded.ampid
    // this._id = this.decoded._id;
    // this.username = this.decoded.username;
    this.customer_name = this.decoded.customer_name;
    // this.dateFillter()
    this.getallalert();

    this.getLevel();
  },
  computed: {
    headers() {
      return [
        // { text: "id", value: "_id", },
        { text: "Date Time", value: "time_detect", sortable: true },
        { text: "Case", value: "usecase" },
        { text: "Device name", value: "device_name" },
        { text: "Log Type", value: "log_type" },
        { text: "Source Ip", value: "src_ip" },
        { text: "Destination Ip", value: "dest_ip" },
        { text: "Destination Port", value: "dest_port" },
        { text: "Protocol", value: "protocol" },
        // { text: "Country", value: "fromsrcip_country" },
        { text: "Label", value: "label" },
        {
          text: "Level",
          value: "level",
          align: "center",
          // fliter: (value) => {
          //   // if (this.arrLevel[this.tab_Level]) return true;
          //   if (value == this.arrLevel[this.tab_Level]) return value;
          //   // if (value == this.select_level) return value;
          //   if (this.arrLevel[this.tab_Level] == "all") {
          //     return true
          //   }
          // },
        },
        { text: "ilog", value: "ilog", align: "center" },
        { text: "Actions ", value: "actions", align: "center" },
        {
          text: "Management",
          value: "management",
          align: "center",
        },
      ];
    },
  },

  // watch: {

  // },

  methods: {

    async getallalert() {
      this.datatables = [];
      this.loadTable = true;
      // console.log(this.startdate);
      // console.log(this.enddate);
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Alert/Show_Alert/",
          {
            customer_name: this.customer_name,
            start_date: this.startdate,
            end_date: this.enddate,
          }
        );

        switch (this.select_level) {
          case "all":
            response.data.map((item) => {
              item.device_name = (item.device_name.toString()).replace('[','').replace(']','').replace('\'','').replace('\'','');
              item.time_detect = new Date(item.time_detect).toLocaleString();
              item.time_log = new Date(item.time_log).toLocaleString();
              this.datatables.push(item);

            });
            break;
          case "low":
            response.data.map((item) => {
              if (item.level == "low") {
                item.device_name = (item.device_name.toString()).replace('[','').replace(']','').replace('\'','').replace('\'','');
                item.time_detect = new Date(item.time_detect).toLocaleString();
                item.time_log = new Date(item.time_log).toLocaleString();
                this.datatables.push(item);

              }
            });
            break;
          case "medium":
            response.data.map((item) => {
              item.device_name = (item.device_name.toString()).replace('[','').replace(']','').replace('\'','').replace('\'','');
              if (item.level == "medium") {
                item.time_detect = new Date(item.time_detect).toLocaleString();
                item.time_log = new Date(item.time_log).toLocaleString();
                this.datatables.push(item);

              }
            });
            break;
          case "high":
            response.data.map((item) => {
              item.device_name = (item.device_name.toString()).replace('[','').replace(']','').replace('\'','').replace('\'','');
              if (item.level == "high") {
                item.time_detect = new Date(item.time_detect).toLocaleString();
                item.time_log = new Date(item.time_log).toLocaleString();
                this.datatables.push(item);

              }
            });
            break;
          default:
            break;
        }
        this.datatables.sort(function(a, b) {
            return new Date(b.time) - new Date(a.time);
        });
              
        this.loadTable = false;
      } catch (error) {
        console.error(error);
      }
    },

    getItem(item) {
      // console.log("++++++++++++")
      // console.log(this.customer_name)
      // console.log(item.device_vendor)
      // console.log(item.stage)
      // console.log(item._id)
      this.time = item.time;
      this.level = item.level;
      this.device_name = item.device_name;
      this.src_ip = item.src_ip;
      this.usecase = item.usecase;
      this.id = item._id;
      this.stage = item.stage;
      // this.check = this.stage;
      // console.log(this.level);
      // console.log(this.stage);
      // console.log(this.id);
      // window.analytics.track('[Alert][Select_Management]', {
      //   time : item.time,
      //   level : item.level,
      //   device_vendor : item.device_vendor,
      //   src_ip : item.src_ip,
      //   usecase : item.category,
      //   id : item._id,
      //   stage : item.stage,
      //   }, {
      //   integrations: {
      //     Amplitude: {
      //       'session_id': this.ampid
      //     }
      //   }
      // })
    },

    async editStage() {
      // console.log("++++++++++++")
      // console.log(this.customer_name)
      // console.log(this.dev_name)
      // console.log(item.stage)
      // console.log(item._id)
      // this.check = this.select_stage;
      // this.check = this.stage;
      // this.$set(this.item.stage, 'stage', this.check)
      // console.log("================================");
      // console.log(this.check);
      // console.log(this.id);
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" + "/api/Alert/Uncheck-Check/",
          {
            customer_name: this.customer_name,
            device_name: this.device_name,
            stage: this.check,
            id: this.id,
          }
        );

        var x = response.data;
        console.log(x);
        this.getallalert();
      //   window.analytics.track('[Alert][EditStage]', {
      //   device_name : this.device_vendor,
      //   stage: this.check,
      //   id: this.id,
      // }, {
      //   integrations: {
      //     Amplitude: {
      //       'session_id': this.ampid
      //     }
      //   }
      // })
        
      } catch (error) {
        console.error(error);
      }
    },
    // customFilter(items){
    //   this.select_level = this.arrLevel[this.tab_Level];
    //   if (this.select_level == "all") return items;
    //     return items.filter(item => {
    //     return item.level.toString().includes(this.select_level);
    //     });
    // },
    getLevel() {
      this.select_level = this.arrLevel[this.tab_Level];
      // console.log(this.select_level);

      this.getallalert();
      // window.analytics.track('[Alert][Tabs][Select_Level]['+this.select_level+']', {
      //   level : this.select_level
      // }, {
      //   integrations: {
      //     Amplitude: {
      //       'session_id': this.ampid
      //     }
      //   }
      // })
    },

    getColor(level) {
      if (level == "high") return "#BC4D2A";
      else if (level == "medium") return "#C98536";
      else if (level == "low") return "#CFAC2E";
      else if (level == "all") return "blue";
    },

    getbtn_Color(stage) {
      if (stage == "open") return "red";
      else if (stage == "close") return "green";
      else if (stage == "in progress") return "blue";
    },

    linkItem(item) {
      this.$router.push({
        name: "alert_history",
        params: {
          time_detect: item.time_detect,
          level: item.level,
          device_vendor: item.device_vendor,
          device_name: item.device_name,
          usecase: item.usecase,
          src_ip: item.src_ip,
          dest_ip : item.dest_ip,

          dest_port: item.dest_port,
          protocol: item.protocol,

          fromsrcip_country: item.fromsrcip_country,

          stage: item.stage,
        },
      });
      window.analytics.track('[Alert][Drilldown_Alert]', {
          time: item.time,
          level: item.level,
          device_vendor: item.device_vendor,
          dev_name: item.dev_name,
          src_ip: item.src_ip,
          usecase: item.usecase,
          id: item._id,
          stage: item.stage,
        }, {
        integrations: {
          Amplitude: {
            'session_id': this.ampid
          }
        }
      })
    },
    async linkilog(item){
      // console.log(item);
      
      this.device_vendor = item.device_vendor;
      console.log(item.time_log);
      this.startdate = Date.parse(item.time_log) - 300000;
      this.enddate = Date.parse(item.time_log);
      // window.open('https://dev.ilog.ai/app/dashboard');
      // // window.location.href ="https://dev.ilog.ai/app/dashboard?PostData1=" + item.port + "&PostData2=" + PostData2;
      console.log(this.startdate)
      console.log(this.enddate)
      console.log(Date.parse(new Date()));

      

      // console.log("src_ip:"+item.src_ip+" AND "+"dest_ip:"+item.dest_ip+" AND "+"dest_port:"+item.dest_port+" AND "+"dev_name:"+item.dev_name+
      //   " AND "+"src_country:"+item.fromsrcip_country)

      let response = await axios.post("https://dev.ilog.ai:8003" + "/api/auth/requestAccess" , {
        device: item.device_vendor,
        start: this.startdate,
        end: this.enddate,
        text: "src_ip:"+item.src_ip+" AND "+"dest_ip:"+item.dest_ip
      })
      // +" AND "+"dest_port:"+item.dest_port+" AND "+"dev_name:"+item.dev_name+
      //   " AND "+"src_country:"+item.fromsrcip_country
      console.log(response);
      window.open(response.data.url, '_blank')
      this.clearFillter();
      

      window.analytics.track('[Alert][Drilldown_ilog]', {
        device: item.device_vendor,
        start: this.startdate,
        end: this.enddate,
        text: item.src_ip
        }, {
        integrations: {
          Amplitude: {
            'session_id': this.ampid
          }
        }
      })
    },
    async dateFillter() {
      this.datatables = []; 
      if (this.start == "" || this.end == "") {
        this.startdate = Date.parse(new Date()) - 2592000000;
        this.enddate = Date.parse(new Date());
        this.getallalert();
      } else {
        this.startdate = Date.parse(this.start);
        this.enddate = Date.parse(this.end);
        this.getallalert();
      }
      window.analytics.track('[Alert][dateFillter]', {
        startdate : this.startdate,
        enddate : this.enddate
      }, {
        integrations: {
          Amplitude: {
            'session_id': this.ampid
          }
        }
      })
    },

    clearFillter() {
      window.analytics.track('[Alert][clearFillter_Time]', {}, {
        integrations: {
          Amplitude: {
            'session_id': this.ampid
          }
        }
      })
      this.datatables = []; 
      this.start = "";
      this.end = "";
      if (this.start == "" || this.end == "") {
        this.startdate = Date.parse(new Date()) - 2592000000;
        this.enddate = Date.parse(new Date());
        this.getallalert();
      }
    },
  },
};
</script>
<style scoped>
.v-tab {
  text-transform: capitalize;
}

.pointer {
  cursor: pointer;
}

.no_pointer {
  cursor: initial;
}

</style>
