<template>
  <app id="allusers">
    <v-row>
      <v-col sm="auto" md="auto" lg="auto">
        <h1 class="page-title">User Analytic</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="auto" md="auto" lg="auto">
        {{ this.user }}
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        {{ this.src_ip }}
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="auto" md="auto" lg="auto">
        <h3 class="page-title">Anomalies / Threats</h3>
      </v-col>
      <v-col sm="2" md="auto" lg="auto">
        <VueCtkDateTimePicker
          v-model="start"
          format="YYYY-MM-DD HH:mm"
          label="Select Time"
          :minute-interval="1"
          class="field-input[data-v-5b500588]"
          dark
        >
        </VueCtkDateTimePicker>
      </v-col>

      <v-col sm="2" md="auto" lg="auto">
        <h2>To</h2>
      </v-col>
      <v-col sm="2" md="auto" lg="auto">
        <VueCtkDateTimePicker
          v-model="end"
          format="YYYY-MM-DD HH:mm"
          label="Select Time"
          :minute-interval="1"
          dark
        >
        </VueCtkDateTimePicker>
      </v-col>

      <v-col sm="2" md="auto" lg="auto">
        <v-btn rounded color="#32CD32" dark class="mx-1" @click="dateFillter">
          SUBMIT
        </v-btn>

        <v-btn rounded color="#121212" class="mx-1" style ="border: 1px solid #C7C7C7;" dark @click="clearFillter">
          <v-icon>mdi-notification-clear-all</v-icon><div style="font-weight: lighter;">CLEAR</div>
        </v-btn>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="4">
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-card-title>
                <p>Threat</p>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="auto">
                    <span class="" style="font-size: 42px">{{
                      this.count_threat
                    }}</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title>
                <p>Anomalies</p>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="auto">
                    <span class="" style="font-size: 42px">{{
                      this.count_anomaly
                    }}</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <!--<v-row>
              <v-col>
                Related Device
              </v-col>
            </v-row>-->
            <v-row>
              <v-col>
                Related Device
                <v-card>
                  <v-card-title>
                    <v-row>
                      <v-col cols="4">
                        <v-select
                          v-model="type"
                          :items="arrtype"
                          @click="select_typedevices()"
                          label="Type"
                          dense
                          rounded
                          outlined
                        ></v-select>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="6">
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-data-table
                    :headers="headers_device"
                    :items="datatables_device"
                    :search="search"
                  ></v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-chart style="width:100%; " :autoresize="true" :options="radaroption"></v-chart>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!---------------->

    <!-- <v-row>
      <v-col sm="auto" md="auto" lg="auto">
        <h3 class="page-title">Anomalies / Threats</h3>
      </v-col>
      <v-col sm="2" md="auto" lg="auto">
        <VueCtkDateTimePicker
          v-model="start"
          format="YYYY-MM-DD HH:mm"
          label="Select Time"
          :minute-interval="1"
          class="field-input[data-v-5b500588]"
          dark
        >
        </VueCtkDateTimePicker>
      </v-col>

      <v-col sm="2" md="auto" lg="auto">
        <h2>To</h2>
      </v-col>
      <v-col sm="2" md="auto" lg="auto">
        <VueCtkDateTimePicker
          v-model="end"
          format="YYYY-MM-DD HH:mm"
          label="Select Time"
          :minute-interval="1"
          dark
        >
        </VueCtkDateTimePicker>
      </v-col>

      <v-col sm="2" md="auto" lg="auto">
        <v-btn rounded color="#32CD32" dark class="mx-1" @click="dateFillter">
          SUBMIT
        </v-btn>

        <v-btn rounded color="#121212" class="mx-1" style ="border: 1px solid #C7C7C7;" dark @click="clearFillter">
          CLEAR
        </v-btn>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title>
            Anomalies
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="arruseranomaly"
            :loading="loadTable"
          >
          </v-data-table>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-card-title>
            Threats
          </v-card-title>
          <v-data-table
            :headers="headers1"
            :items="arruserthreat"
            :loading="loadTable"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </app>
</template>

<script>
import jwtDecode from "jwt-decode";
import axios from "axios";

import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/radar";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
  name: "user_analytic",
  components: {
    "v-chart": ECharts,
    VueCtkDateTimePicker,
  },
  data() {
    return {
      loadTable: false,
      user: '',
      startdate: Date.parse(new Date()) - 2592000000,
      enddate: Date.parse(new Date()),
      arrtype: [],
      datatables_device: [],
      headers_device: [
        {
          text: "Device Name",
          align: "start",
          sortable: false,
          value: "dev_name",
        },
        { text: "Type", value: "log_type" },
        { text: "Anomaly", value: "anomaly" },
        { text: "Threat", value: "threat" },
        { text: "Total", value: "count" },
      ],
      radaroption: {},
      arrlog_type_radar: [],
      arrcount_radar: [0, 0, 0, 0, 0, 0],
      arrcount_anomaly_radar: [0, 0, 0, 0, 0, 0],
      arrcount_threat_radar: [0, 0, 0, 0, 0, 0],
      max: "",

      arrcount: [0, 0],
      count_threat: "",
      count_anomaly: "",
      arrsrc_ip: [],
      src_ip: "",

      start: "",
      end: "",
      // start1: "",
      // end1: "",
      arruseranomaly: [],
      arruserthreat: [],
    };
  },
  created() {
    this.token = sessionStorage.getItem("user-token");
    this.decoded = jwtDecode(this.token);
    this._id = this.decoded._id;
    this.ampid = this.decoded.ampid
    this.customer_name = this.decoded.customer_name;
    this.user = this.$route.params.user;
    if (this.customer_name == undefined || this.user == undefined) {
      this.$router.replace({ name: "users" });
    }
    this.geteachdevice();
    this.getradar();
    this.getcount();
    this.getip();
    this.getanomaly();
    this.getthreat();
  },

  computed: {
    headers() {
      return [
        { text: "Usecase", value: "usecase" },
        { text: "Device", value: "dev_name" },
        { text: "Date", value: "time" },
      ];
    },

    headers1() {
      return [
        { text: "Usecase", value: "usecase" },
        { text: "Device", value: "dev_name" },
        { text: "Date", value: "time" },
      ];
    },
  },

  methods: {
    async getip() {
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/User_Analytic/Show_User_Anomaly/",
          {
            customer_name: this.customer_name,
            user: this.user,
            start_date: this.startdate,
            end_date: this.enddate,
          }
        );
        response.data.map((item) => {
          // this.arrcount.push(item);
          this.arrsrc_ip.push(item.src_ip);
        });

        this.src_ip = this.arrsrc_ip[0];
      } catch (error) {
        console.error(error);
      }
    },

    async getcount() {
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/User_Analytic/User_Check_Threat_Anomaly_allDevice/",
          {
            customer_name: this.customer_name,
            user: this.user,
            start_date: this.startdate,
            end_date: this.enddate,
          }
        );
        response.data.map((item) => {
          // this.arrcount.push(item);
          switch (item.label) {
            case "threat":
              this.arrcount[0] = item.count;
              break;
            case "anomaly":
              this.arrcount[1] = item.count;
              break;
            default:
              break;
          }
        });

        this.count_threat = this.arrcount[0];
        this.count_anomaly = this.arrcount[1];
      } catch (error) {
        console.error(error);
      }
    },

    async geteachdevice() {
      this.loadTable = true;
      this.datatables_device = [];
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/User_Analytic/User_Check_Threat_Anomaly_eachDevice/",
          {
            customer_name: this.customer_name,
            user: this.user,
            start_date: this.startdate,
            end_date: this.enddate,
          }
        );
        response.data.map((item) => {
          this.datatables_device.push(item);
          this.arrtype.push(item.log_type);
        });

        this.loadTable = false;
      } catch (error) {
        console.error(error);
      }
    },
    async getradar() {
      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/User_Analytic/User_Check_Threat_Anomaly_eachLogtype/",
          {
            customer_name: this.customer_name,
            user: this.user,
            start_date: this.startdate,
            end_date: this.enddate,
          }
        );
        response.data.map((item) => {
          //this.arrlog_type_radar.push(item.log_type);
          // if(item.log_type == 'firewall')
          // this.arrcount_radar[0] = item.count
          // else if(item.log_type == 'mobile')
          // this.arrcount_radar[1]= item.count
          // else if(item.log_type == 'pc')
          // this.arrcount_radar[2]= item.count
          // else if(item.log_type == 'server')
          // this.arrcount_radar[3]= item.count
          // else if(item.log_type == 'router')
          // this.arrcount_radar[4]= item.count
          // else if(item.log_type == 'switch')
          // this.arrcount_radar[5] = item.count

          switch (item.log_type) {
            case "firewall":
              this.arrcount_radar[0] = item.count;
              this.arrcount_anomaly_radar[0] = item.anomaly;
              this.arrcount_threat_radar[0] = item.threat;
              break;
            case "ad":
              this.arrcount_radar[1] = item.count;
              this.arrcount_anomaly_radar[1] = item.anomaly;
              this.arrcount_threat_radar[1] = item.threat;
              break;
            case "pc":
              this.arrcount_radar[2] = item.count;
                            this.arrcount_anomaly_radar[2] = item.anomaly;
              this.arrcount_threat_radar[2] = item.threat;
              break;
            case "server":
              this.arrcount_radar[3] = item.count;
                            this.arrcount_anomaly_radar[3] = item.anomaly;
              this.arrcount_threat_radar[3] = item.threat;
              break;
            case "router":
              this.arrcount_radar[4] = item.count;
                            this.arrcount_anomaly_radar[4] = item.anomaly;
              this.arrcount_threat_radar[4] = item.threat;
              break;
            case "switch":
              this.arrcount_radar[5] = item.count;
                            this.arrcount_anomaly_radar[5] = item.anomaly;
              this.arrcount_threat_radar[5] = item.threat;
              break;
            default:
              break;
          }
        });
        this.max = Math.max(...this.arrcount_radar) + 2;

        // console.log("++++++++++++++++");
        // console.log(this.arrcount_radar);

        this.radaroption = {
          tooltip: {
            // trigger: "axis",
          },
          // title: {
          //     text: '基础雷达图'
          // },
          legend: {
              data: ['Total','Anomaly','Threat'],
                          textStyle: {
              color: "#FFFF",
            },
          },
          grid: {
            top: 10,
            containLabel: true,
          },
          radar: {
            // shape: 'circle',
            indicator: [
              { name: "firewall", max: this.max },
              { name: "ad", max: this.max },
              { name: "pc", max: this.max },
              { name: "server", max: this.max },
              { name: "router", max: this.max },
              { name: "switch", max: this.max },
            ],
          },
          series: [
            {
              // name: '预算 vs 开销（Budget vs spending）',
              type: "radar",
              data: [
                {
                  value: this.arrcount_radar,
                  name: 'Total'
                },
                {
                  value: this.arrcount_anomaly_radar,
                  name: 'Anomaly',
                  areaStyle: {}
                },
                {
                  value: this.arrcount_threat_radar,
                  name: 'Threat',
                  areaStyle: {}
                }
              ],
            },
          ],
        };
      } catch (error) {
        console.error(error);
      }
    },

    async getanomaly() {
      this.loadTable = true;
      // console.log(this.customer_name);
      // console.log(this.user);
      // console.log(this.startdate);
      // console.log(this.enddate);

      try {
        let response = await axios.post(
          "https://backstage.v-insight.co:8001" +
            "/api/User_Analytic/Show_User_Anomaly/",
          {
            customer_name: this.customer_name,
            user: this.user,
            start_date: this.startdate,
            end_date: this.enddate
          }
        );
        response.data.map((item) => {
          item.time = new Date(item.time).toLocaleString();
          this.arruseranomaly.push(item);
          // this.src_ip = response.data.src_ip;
          // console.log("++++++")
          // console.log(this.src_ip)
        });
        // this.src_ip = response.data.src_ip;
        this.loadTable = false;
        // console.log("++++++")
        // console.log(this.src_ip)
        // console.log(this.arruseranomaly)
      } catch (error) {
        console.error(error);
      }
    },

    async dateFillter() {
      window.analytics.track('[User_analytic][dateFillter]', {
        startdate : this.startdate,
        enddate : this.enddate
      }, {
        integrations: {
          Amplitude: {
            'session_id': this.ampid
          }
        }
      })
      this.arruseranomaly = [];
      this.arruserthreat = [];
      if (this.start == '' || this.end == '') {
        this.startdate = Date.parse(new Date()) - 2592000000;
        this.enddate = Date.parse(new Date());
        this.getanomaly();
        this.getthreat();
        this.getcount();
        this.geteachdevice()
        this.getradar() 
      } else {
        this.startdate = Date.parse(this.start);
        this.enddate = Date.parse(this.end);
        this.getanomaly();
        this.getthreat();
        this.getcount();
        this.geteachdevice()
        this.getradar() 
      }
    },

    async getthreat() {
      this.loadTable = true;
      console.log(this.customer_name)
      console.log(this.user)
      console.log(this.startdate)
      console.log(this.enddate)

      try {
        let response = await axios.post("https://backstage.v-insight.co:8001" + "/api/User_Analytic/Show_User_Threat/",
          {
            customer_name: this.customer_name,
            user: this.user,
            start_date: this.startdate,
            end_date: this.enddate,
          }
        );
        response.data.map((item) => {
          item.time = new Date(item.time).toLocaleString();
          this.arruserthreat.push(item);
          // this.src_ip = response.data.src_ip;
          // console.log("++++++")
          // console.log(this.src_ip)
        });
        this.loadTable = false;
        // console.log("++++++");
        // console.log(this.src_ip)
        // console.log(this.arruserthreat);
      } catch (error) {
        console.error(error);
      }
    },

    select_typedevices(){
    window.analytics.track('[User_analytic][Devicestype_Fillter]', {
    }, {
        integrations: {
          Amplitude: {
            'session_id': this.ampid
          }
        }
    })
    },

    clearFillter (){
      window.analytics.track('[User_analytic][clearFillter_Time]', {}, {
        integrations: {
          Amplitude: {
            'session_id': this.ampid
          }
        }
      })
      this.arruseranomaly = [];
      this.arruserthreat = [];
      this.start = '';
      this.end = '';
      if (this.start == '' || this.end == '') {
        this.startdate = Date.parse(new Date()) - 2592000000;
        this.enddate = Date.parse(new Date());
        this.getanomaly();
        this.getthreat();
      }
    }
  },
};
</script>
